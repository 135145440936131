import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux'
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment';


export default function OnSnoozeClicked({ 
  entityData,
  groupCheckbox,
  renderRemote,
  setRenderRemote,
  snoozeTo, 
  snoozeDateOption, 
  snoozeToOptions, 
  setSnoozeTo, 
  setSnoozeToDate, 
  resetSnooze, 
  snoozeToDate, 
  snoozeButtonClassName
}) {

  const account = useSelector((state) => state.account);
  const [saving, setSaving] = useState(false);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const getDaysToEmpty = async (serialNumber, tonerType) => {
    const result = await Axios.get(`/entities/workflow/actionDaysToEmpty/daysOnly/${serialNumber}/${tonerType}`).then(api => api.data.result);
    return (result && result.daysToEmpty) ? result.daysToEmpty : false;
  }

  const onSnoozeConfirmed = async () => {

    try {

      setSaving(true);
      
      const checked = Array.from(new Set(Object.keys(groupCheckbox.current).filter(x => groupCheckbox.current[x]))).map(x => Number(x));
      const filtered = entityData.current.result.filter(x => checked.includes(Number(x.tonerID)));
      const notes = { data: [] };

      for (let item of filtered) {
  
        const dateNow = moment().utc().format("YYYY-MM-DD HH:mm:ss");
  
        const daysToEmpty = (snoozeTo !== snoozeDateOption) 
          ? await getDaysToEmpty(item.serialNumber, item.tonerType)
          : null;
  
        if((snoozeTo !== snoozeDateOption) && !daysToEmpty) continue;
  
        const snoozeBase = (snoozeTo === snoozeDateOption)
          ? { isSnoozed: 1, snoozeType: 1, daysLeft: moment(snoozeToDate).diff(moment().subtract(10, 'minutes'), 'days'), snoozeBotLastCheck: dateNow, snoozeUntilDate: snoozeToDate, snoozedBy: account.azureId, isSnoozedQueued: 1 }
          : { isSnoozed: 1, snoozeType: 2, daysLeft: daysToEmpty, snoozeBotLastCheck: dateNow, snoozeUntilDate: moment().add(daysToEmpty, 'days'), snoozedBy: account.azureId, isSnoozedQueued: 1 };
  
        const notesText = (snoozeTo === snoozeDateOption)
          ? `Record Snoozed until date: ${snoozeToDate}`
          : `Record Snoozed until empty: ${daysToEmpty}`
  
        const found = filtered.findIndex(x => Number(x.tonerID) === Number(item.tonerID));
  
        filtered[found] = { ...item, ...snoozeBase };
        notes.data.push({ tonerID: item.tonerID, notes: notesText });

        filtered.forEach(x => delete x.notes);

      }
      
      const result = await Axios.put(`/entities/workflow/actionSetSnoozed`, { toner: filtered, notes: notes });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        Object.keys(groupCheckbox.current).forEach(x => groupCheckbox.current[x] = true);
        setRenderRemote(!renderRemote);
        resetSnooze();
      }

      setSaving(false);
    
    } catch (err) {
      console.log(err);
      setSaving(false);
      setBannerError({ error: true, message: exceptionError });
    }
  }

  return <Fragment>
    <div className='modalBackground'>
      <div className='modalContent'>
        <div className='text-xlarge text-center mar-b15'>Snooze Options</div>
        <table className='mar-b15'>
          <tbody>
            <tr>
              <td><span className='mar-r10'>Snooze to</span></td>
              <td><UILIB.Select value={snoozeTo} onChange={(e) => setSnoozeTo(e.target.value)} data={snoozeToOptions} /></td>
            </tr>
            <tr className={(snoozeTo !== snoozeDateOption) ? 'hidden' : ''}>
              <td><span className='mar-r10'>Select date to snooze until</span></td>
              <td><UILIB.TextInput type='datetime-local' className='textInput input' value={snoozeToDate} onChange={(e) => setSnoozeToDate(moment(e.target.value).format("YYYY-MM-DD HH:mm:ss"))} /></td>
            </tr>
          </tbody>
        </table>
        <div className='flex-container end '>
          <UILIB.Button loading={saving} disabled={saving} className={snoozeButtonClassName} value='Snooze' onClick={async () => await onSnoozeConfirmed()} />
          <UILIB.Button disabled={saving} className='flex-item evenly grey mar-l5' value='Cancel' onClick={() => resetSnooze()} />
        </div>
        {bannerError.error && <div className="flex-container end width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
    </div>
  </Fragment>
}