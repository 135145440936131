import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch } from 'react-redux';
import UILIB from 'components';
import Axios from 'classes/axios';
import { setFilterVars, tableContainer, actionOpenNotesDrawer, getYesNo } from '../functions';
import Modules from '../refurb/modules';
import AvailableDevicesTable from "../tables/availableDevicesTable";
import RefurbDevicesTable from "../tables/refurbDevicesTable";

export default function RefurbDevices() {

  const [filter, setFilter] = useState({ button: 'Available' });
  const [stores, setStores] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const actionAsync = async () => { 
      const storesResult = await getStores();
      const storesMapped = storesResult.map(store => { return { label: store.Name, value: store.Id } });
      const selectedMapped = storesResult.map(store => store.Id);
      setSelected(selectedMapped);
      setStores(storesMapped); 
    }
    actionAsync();
  }, []);

  const getStores = async () => {
    const results = await Axios.get(`/entities/stores/?&$filter=Name like Ready&$select=Id and Name`).then(api => api.data);
    return results.result;
  }

  const handleStoreChanged = (selected) => {
    setSelected([...selected]);
  };

  const handleButtonsChanged = (buttonName) => {
    const newTableFilter = { ...filter };

    if (newTableFilter.button === buttonName) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = buttonName;
    }
    setFilter(newTableFilter);
  };

  return (

    <Fragment>
      <UILIB.Paper className="width-100">
        <div class="flex-container row mar-b10">
          <div class="flex-item flex-grow-1 start wrap">
            <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Available' ? 'primary' : 'secondary')} name='Available' value='Available' onClick={(ev) => handleButtonsChanged(ev.target.name)} />
                  <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Reserved' ? 'primary' : 'secondary')} name='Reserved' value='Reserved' onClick={(ev) => handleButtonsChanged(ev.target.name)} />
                  <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Sold' ? 'primary' : 'secondary')} name='Sold' value='Sold' onClick={(ev) => handleButtonsChanged(ev.target.name)} />
          </div>
          <div className='flex-item end'>
            <UILIB.MultiSelect outerclassNname="min-width-50" data={stores} placeholder="All Stores" selected={selected} onChange={(ev) => { handleStoreChanged(ev.target.value) }} />
          </div>
        </div>
    </UILIB.Paper>
    {filter.button === 'Available' ? <AvailableDevicesTable filter={filter} selected={selected} /> : <RefurbDevicesTable filter={filter} selected={selected} />}
  </Fragment>
  )
}