import { useState, useEffect, useRef, Fragment } from 'react';
import moment from "moment";
import UILIB from 'components';
import Axios from 'classes/axios';
import { getBatching, getDaysOfWeek } from '../../functions';


export default function BatchedOrders({ entityLookup }) {

  const rowData = moment.weekdays();
  const entityData = useRef({});
  const [selected, setSelected] = useState({});
  const [saving, setSaving] = useState(false);
  const [batchedSelect, setBatchedSelect] = useState(-1);
  const emptyError = { error: false, message: '' };
  const [selectError, setSelectError] = useState(emptyError);
  const [bannerError, setBannerError] = useState(emptyError);
  const batchedCondition = (Number(batchedSelect) === 1 && (saving || !Object.keys(selected).some(x => selected[x])));
  const exceptions = [400,404];
 
  const selectWarning = 'Please choose either No Batching or Batched Weekly';
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const handleApplyBatching = async (context) => {
    setSaving(true);
    if(Number(batchedSelect) !== -1) {
      const daysOfWeek = Array.from(new Set(Object.keys(selected).filter(x => selected[x]))).filter(x => (x && x !== 'undefined')).map(x => x);
      const baseData = { tonerBatchPeriod: batchedSelect, tonerBatchPeriodDayNo: daysOfWeek, isBatched: batchedSelect };
      try {
        const result = await Axios.put(`/entities/workflow/configureBatchedOrders/${entityData.current}/${context}`, { ...baseData });
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          setBannerError(emptyError);
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
    } else {
      setSelectError({ error: true, message: selectWarning });
    }
    setSaving(false);
  }

  const handleSelected = (event) => {
    setBatchedSelect(event.target.value);
    setSelectError(emptyError);
  }

  const handleSetBatched = async (data) => {
    const filter = `CU_KEY eq ${data}`;
    const select = `tonerBatchPeriod and tonerBatchPeriodDayNo`;
    const paging = '&$limit=1&&$offset=0&$order=userID&$direction=DESC';
    const SAUSERS = await Axios.get(`/entities/emailer_SAUSERS/?&$filter=${filter}&$select=${select}${paging}`).then(res => res.data);
    const days = Array.from(SAUSERS.result[0].tonerBatchPeriodDayNo.split(','));
    setSelected(days.map(x => { return { [getDaysOfWeek(Number(x))]: true } }).reduce((acc, obj) => ({ ...acc, ...obj }), {}));
    setBatchedSelect(SAUSERS.result[0].tonerBatchPeriod);
  }

  useEffect(() => {
    const actionAsync = async () => {
      if(entityLookup.hasOwnProperty('customerId')) {
        entityData.current = entityLookup.customerId;
        await handleSetBatched(entityData.current);
      }
      if(entityLookup.hasOwnProperty('equipmentId')) {
        const equipmentId = entityLookup['equipmentId'];
        const equipmentResult = await Axios.get(`/entities/equipment/${equipmentId}/?&$select=CustomerId and SerialNumber`).then(res => res.data.result[0]);
        entityData.current = equipmentResult.CustomerId;
        await handleSetBatched(entityData.current);
      }
    }
    actionAsync();
  }, [entityLookup])


  return <Fragment>
    <UILIB.Paper className='flex-container row width-100 consumablesHub batchedOrders mar-b20'>
      <div className='flex-item start width-100'><h4>Batched Orders</h4></div>
      <div className='flex-container start wrap row width-100 mar-10'>
        <div className='lex-item start width-100 wrap'>
          <div className='flex-item start width-100 mar-b15'>
              <UILIB.Select 
                className="consumablesHub batched-select-dropdown" 
                outerclassNname="consumablesHub batched-select-dropdown" 
                disabled={saving}
                value={String(batchedSelect)} 
                data={getBatching()}
                onChange={(ev) => handleSelected(ev)} />
            </div>
            {selectError.error && <div className="flex-item start width-100">
              <div className="errored message">{selectError.message}</div>
            </div>}
        </div>
        <div className='flex-item start flex-grow-1 wrap'>
          {Number(batchedSelect) !== 0 && <Fragment>
            <div className="flex-item flex-shrink-0 align-center mar-r10">Batch Until</div>
            <UILIB.SpawnMultiselectRow 
              outerclassNname="flex-container row wrap" 
              className="consumablesHub weekDayButton" 
              disabled={!entityData.current || Number(batchedSelect) === -1}
              data={rowData} 
              selected={selected} 
              setSelected={setSelected} />
            <div className="flex-item flex-shrink-0 align-center mar-r10 consumablesHub batched-every-week">Every Week</div>
          </Fragment>}
        </div>
        <div className='flex-item end wrap'>
          <UILIB.Button 
            disabled={(saving || Number(batchedSelect) === -1 || !entityData.current || batchedCondition || !Object.values(selected).some(x => x))} 
            loading={saving}
            className="primary" 
            value="Apply to all sites for this Customer" 
            onClick={async () => await handleApplyBatching(1)} />
          <UILIB.Button 
            disabled={(saving || Number(batchedSelect) === -1 || !entityData.current || batchedCondition || !Object.values(selected).some(x => x))} 
            loading={saving}
            value="Apply to only this site" 
            onClick={async () => await handleApplyBatching(0)} />
        </div>
        <div className='flex-item end wrap width-100'>
          {bannerError.error && <div className="flex-item end width-100">
            <div className="errored message">{bannerError.message}</div>
          </div>}
        </div>
      </div>
    </UILIB.Paper>
  </Fragment>
}