import { useState, useEffect, Fragment } from "react"
import { useHistory } from 'react-router-dom'
import Axios from 'classes/axios'
import UILIB from 'components'
import moment from 'moment'


const headerData = [
  { label: "Reference", align: "left", value: "reference", type: 'string', width: "100px" }, 
  { label: "Company", value: "companyName", type: 'string' }, 
  { label: "Sales Person", value: "salesPerson", type: 'string' },
  { label: "Move Date", value: "moveDate", align: "center", type: 'date', width: "120px" },
  { label: "Created Date", value: "createdAtDate", align: "center", type: 'date', width: "120px" },
  { label: "Download PDF", value: "downloadPDF", align: "center", width: "150px" }, 
  { label: "Status", align: "center", value: "status", type: 'string', width: "300px" }, 
  { label: "View / Edit", align: "center", value: "editView", type: 'number', width: "200px" }, 
  { label: "Delete", align: "center", value: "delete", type: 'number', width: "100px" }
]


export default function ExistingDeviceMovementTable({ tableFilter, tab }) {

    const history = useHistory();
    const [downloading, setDownloading] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [pageSelect, setPageSelect] = useState(0);
    const [render, setRender]= useState(0);


    const handleDelete = async (row) => {
      if(window.confirm(`Are you sure you wish to DELETE this request?`)) {
        await Axios.delete(`/logistics/deviceMovement/delete/${row.id}`);
        setRender(!render);
      }
    }

    const generatePDF = async (row) => {
        setDownloading(d => ({ ...d, [row.id]: {status: true, error: false, message: 200}}))
        await Axios.get(`/logistics/deviceMovement/generatePDF/${row.uuid}`).then((res) => {
            var link = document.createElement('a');
            link.download = res.data.filename;
            link.href = 'data:application/octet-stream;base64,' + res.data.content;
            document.body.appendChild(link);
            link.click();
            setDownloading(d => ({ ...d, [row.id]: {status: false, error: false, message: 200}}))
        }).catch(err => {
            console.log(err);
            setDownloading(d => ({ ...d, [row.id]: {status: false, error: true, message: err.status}}));
        })
    }

    const data = tableData.map(row => {

        let status = <UILIB.Chip className="table grey text-small width-100" value="Draft" />
        let statusTxt = "Draft";

        let buttonValueEditview = "Edit"
        switch(true) {
          case (row.processStage === 1):
              buttonValueEditview = "Edit / Generate Quote"
              status = <UILIB.Chip className="table orange text-small width-100" value="Chargeable Request Submitted" />
              statusTxt = "Chargeable Request Submitted"
            break;
          case (row.processStage === 2):
              buttonValueEditview = "View Read Only"
              status = <UILIB.Chip className="table orange text-small width-100" value="None Chargeable Request Complete" />
              statusTxt = "None Chargeable Request Complete"
            break;
          case (row.processStage === 3):
              buttonValueEditview = "Submit Quote"
              status = <UILIB.Chip className="table orange text-small width-100" value="Chargeable Request Submitted" />
              statusTxt = "Chargeable Request Submitted"
            break;
          case (row.processStage === 4):
              buttonValueEditview = "View Read Only"
              status = <UILIB.Chip className="table orange text-small width-100" value="Chargeable Request Pending Sign-Off" />
              statusTxt = "Chargeable Request Pending Sign-Off"
            break;
          case (row.processStage === 5):
              buttonValueEditview = "View Read Only"
              status = <UILIB.Chip className="table orange text-small width-100" value="Chargeable Request Complete" />
              statusTxt = "Chargeable Request Complete"
            break;
          default:
              break;
        }

        let editView = 
          <UILIB.Button 
            className="table green text-small width-100" 
            value={buttonValueEditview} 
            onClick={() => history.push({
              pathname: `/logisticsHub/deviceMovement/${row.uuid}`, 
              state: {
                existingForm: true,
                uuid: row.uuid,
                tab: tab
              }
            })} 
          />


        return {
            reference: { value: row.reference, raw: row.reference },
            companyName: { value: row.companyName, raw: row.companyName },
            salesPerson: { value: row.salesPerson, raw: row.salesPerson },
            moveDate: { value: row.moveDate ? moment(row.moveDate).utc().format('DD/MM/YYYY') : "N/A", raw: row.moveDate },
            createdAtDate: { value: row.createdAt ? moment(row.createdAt).utc().format('DD/MM/YYYY') : "N/A", raw: row.createdAt },
            collectionAddress: { value: row.collectionAddress, raw: row.collectionAddress },
            delAddress: { value: row.delAddress, raw: row.delAddress
            },
            downloadPDF: { 
                value:
                <UILIB.Button 
                    className={downloading[row.id] && downloading[row.id].error ? "colour background red table text-small width-100" : "colour background orange table text-small width-100"} 
                    onClick={() => {generatePDF(row)}} 
                    loading={downloading[row.id] && (downloading[row.id].status && !downloading[row.id].error)}
                    value={"Download PDF"}  
                />,
                raw: "Download PDF"
            },
            status: { value: status, raw: statusTxt },
            editView: { value: editView, raw: editView },
            delete: { value: <UILIB.Button className="table red text-small width-100" value="Delete" onClick={() => handleDelete(row)} />, raw: 0 }
        }
    });



    const retrieveTableData = () => {
        setLoadingData(true);
        let qs = "?processStage=-1"
        Axios.get(`/logistics/deviceMovement/getTableData${qs}`).then((data) => {
            setTableData(data.data)
            setLoadingData(false);
        })
    };


    useEffect(() => {
      retrieveTableData();
    }, [tableFilter]);

    return <Fragment>
      <UILIB.Paper className="mar-b15 no-shadow width-100">
        <UILIB.Table
          height="100%"
          className="small"
          overflowX="auto"
          overflowY="hidden"
          header={headerData}
          data={data}
          loading={loadingData}
          sortable={true}
          filter={true}
          maxRows={40}
          autoResize={false}
          pageSelect={pageSelect}
          setPageSelect={setPageSelect}
          defaultOrderBy="purchaseRef"
          defaultOrderDir="DESC"
        />
      </UILIB.Paper>
    </Fragment>
}