import { useState, memo, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import UILIB from "components";
import Modules from './Modules';


const deviceExceptions = memo(() => {

  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);

  function actionChangeTab (newId) {
    setCurrentTab(newId);
  }

  const tabs = [
    { label: "Customers", value: 0 },
    { label: "Devices", value: 1 }];

  return <Fragment>
    <div className="flex-container row center width-100 pad-l5 pad-r5">
      <div className="flex-container column width-100 mar-t10">
        <UILIB.Paper className='flex-item row width-100 mar-b5'>
          <div className='flex-item start width-100 mar-b20'><h3>Device Exceptions</h3></div>
          <div className='flex-item start flex-grow-1'>
            <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
          </div>
        </UILIB.Paper>
      </div>
      <div className="flex-container row width-100">
        <Fragment>
          <UILIB.SubNav onClick={(newId) => actionChangeTab(newId)} tabs={tabs} currentTab={currentTab} outerClasses="mar-t15 mar-b15" />
          {currentTab === 0 && <Modules.CustomerLookup />}
          {currentTab === 1 && <Modules.DeviceLookup />}
        </Fragment>
      </div>
    </div>
  </Fragment>
});

export default deviceExceptions;
