import { useState, useEffect } from 'react';
import UILIB from 'components';
import { statCard } from '../../../../../classes/statsCard'
import { useHistory, useParams } from "react-router-dom";
import BlockDealsTable from './blockDealsTable';
import moment from 'moment';
import Axios from "classes/axios";
import { formatCurrency } from "classes/format";
export default function DealsView() {

    const history = useHistory();
    const { dealStatus } = useParams();
    const statContainerClass = 'cardGeneric';
    const statChildClass = 'cardGeneric cardChild';


    const [tableFilter, setTableFilter] = useState({
        status: dealStatus === "all" ? "" : dealStatus,
        resetTableFilters: false
    })

    const resetTableFilter = () => {
        setTableFilter({
            status: dealStatus === "all" ? "" : dealStatus,
            resetTableFilters: true
        });
    };

    const [topStats, setTopStats] = useState({});
    const [loading, setLoading] = useState(true);
    const [dates, setDates] = useState({
        startDate: moment().subtract(10, 'years').startOf("month").startOf("day").utc().format("YYYY-MM-DD 00:00:00").toString(),
        endDate: moment().endOf("month", "YYYY-MM-DD 23:59:59").endOf("day").utc().format("YYYY-MM-DD 23:59:59").toString()
    });

    const updateDates = (newDate) => {
        let ourDates = JSON.parse(JSON.stringify(dates));
        ourDates[newDate.target.name] = newDate.target.value;
        setDates(ourDates);

    }

    const handleFiltersChanged = (buttonName) => {
        const newTableFilter = { ...tableFilter }
        if (newTableFilter.status === buttonName) {
            newTableFilter.status = ''
        }
        else {
            newTableFilter.status = buttonName
        }
        setTableFilter(newTableFilter)
    }

    const getCounts = async () => {
        Axios.get(`/sales/deals/dealCounts?justMe=true&startDate=${dates.startDate}&endDate=${dates.endDate}`).then((counts) => {
            setTopStats(counts.data);
            setLoading(false);
        });
    }
    useEffect(() => {
        getCounts()
        // eslint-disable-next-line
    }, [dates])

    if (loading) return <UILIB.Loading type={3} />

    return <div className="flex-container row mar-l5 mar-r5">
        <div className="col-xs-12 mar-b15 mar-t5">
            <UILIB.Button className="white outline" value="Back" iconBefore="icon-arrow-left" onClick={() => history.push('/salesHub/accountmanagerview')} />
        </div>
        <div className="flex-item flex-grow-1 width-100">
            <h2>Your Deals</h2>
        </div>
        <div className="flex-item row width-100 mar-t5 mar-b10">
          {statCard(false, false, false, 'Unapproved', (topStats.AwaitingApproval || 0), statContainerClass, statChildClass)}
          {statCard(false, false, false, 'Active Orders', (topStats.ApprovedAwaitingInvoice || 0), statContainerClass, statChildClass)}
          {statCard(false, false, false, 'Declined This Month', 'COMING SOON', statContainerClass + ' grey', statChildClass)}
          {statCard(false, false, false, 'Total Active GP', formatCurrency(topStats.GP), statContainerClass, statChildClass)}
          {statCard(false, false, false, 'Booked This Month', formatCurrency(topStats.bookedThisMonth), statContainerClass, statChildClass)}
          {statCard(false, false, false, 'Invoiced This Month', formatCurrency(topStats.ITM), statContainerClass, statChildClass)}
        </div>
        <div className="flex-container row width-100 mar-b10">
          <div className="flex-item start flex-grow-1 wrap">
            <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!tableFilter.status || tableFilter.status === 'awaitingApproval' ? "primary" : "secondary")} name="awaitingApproval" value="Awaiting Approval" onClick={(ev) => handleFiltersChanged(ev.target.name)} />
            <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!tableFilter.status || tableFilter.status === 'active' ? "primary" : "secondary")} name="active" value="Active" onClick={(ev) => handleFiltersChanged(ev.target.name)} />
            <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!tableFilter.status || tableFilter.status === 'invoiced' ? "primary" : "secondary")} name="invoiced" value="Invoiced" onClick={(ev) => handleFiltersChanged(ev.target.name)} />
            <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!tableFilter.status || tableFilter.status === 'archived' ? "primary" : "secondary")} name="archived" value="Archived" onClick={(ev) => handleFiltersChanged(ev.target.name)} />
            <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small  primary"} name="declined" value="Declined" onClick={() => { }} disabled={true} />
            <UILIB.Button className={"mar-l20 mar-r5 mar-b5 mar-t5 small green"} name="resetFilters" value="Reset Filters" onClick={resetTableFilter} />
          </div>
          <div className="flex-item end">
            <p style={{ float: 'right', padding: 0 }} className="mar-r15 mar-t15 center">Date Submitted:</p>
            <UILIB.TextInput type="date" className="textInput input" name="startDate" outerclassNname="mar-r10 mar-t15" value={dates.startDate} onChange={updateDates} />
            <UILIB.TextInput type="date" className="textInput input" name="endDate" outerclassNname="mar-t15" value={dates.endDate} onChange={updateDates} />
          </div>
        </div>
        <div className="flex-item flex-grow-1">
            <BlockDealsTable filter={tableFilter} dealStatus={dealStatus} theDates={dates} />
        </div>
    </div >
}
