import { Fragment, useState, useEffect, useRef } from 'react'
import { TableHead, TableContainer } from '@mui/material';
import moment from 'moment'
import UILIB from 'components'
import useWindowSize from 'components/useWindowSize';
import { exportData } from "classes/export";
import ClickAway from '../ClickAway'



function sortData(data, field, type) {
    data.sort((a, b) => {
        let valA = a[field].hasOwnProperty('raw') ? a[field].raw : a[field]
        let valB = b[field].hasOwnProperty('raw') ? b[field].raw : b[field]
        switch (type) {
            case 'number': return parseFloat(valA || 0) - parseFloat(valB || 0)
            case 'string': {
                valA = (valA && valA !== null) && valA.toUpperCase();
                valB = (valB && valB !== null) && valB.toUpperCase();
                if (valA < valB) {
                    return -1;
                }
                if (valA > valB) {
                    return 1;
                }
                return 0;
            }
            case 'date': return new Date(valA) - new Date(valB);
            default: return 0
        }
    })
}


export default function Table({ 
  data = [], 
  header = [], 
  className = "", 
  overflowX = 'auto',
  overflowY = 'auto',
  loading = false, 
  sortable = false, 
  defaultOrderBy, 
  defaultOrderDir, 
  defaultFilter = {},
  filter = false, 
  resetFilters = false, 
  maxRows, 
  height = 450,
  fitToParent = false, 
  width,
  autoResize = false, 
  autoResizeOffset = 0,
  pageSelect,
  setPageSelect,
  showRecords = true,
  exportEnable = false,
  tableFilterHandler = null,
  tableRender = null,
  setTableRender = null
}) 
{
    const enablePagination = tableFilterHandler !== null;
    const enableTableRender = tableRender !== null && setTableRender !== null;
    const [orderBy, setOrderBy] = useState(sortable ? defaultOrderBy || header[0].value : '');
    const [orderDir, setOrderDir] = useState(defaultOrderDir || 'ASC');
    const [filters, setFilters] = useState(defaultFilter);
    const [filtering, setFiltering] = useState(null);
    const [filterUi, setFilterUi] = useState(null);
    const [page, setPage] = useState(pageSelect || 0);
    const enablePaginationExtras = useRef(maxRows || tableFilterHandler ? true : false);
    const [recordsPageSelected, setRecordsPageSelected] = useState(1);
    const recordsPerPage = useRef([
      { label: enablePagination ? tableFilterHandler.current.limit : maxRows, value: 1 },
      { label: enablePagination ? tableFilterHandler.current.limit * 2 : maxRows * 2, value: 2 },
      { label: enablePagination ? tableFilterHandler.current.limit * 3 : maxRows * 3, value: 3 },
      { label: enablePagination ? tableFilterHandler.current.limit * 4 : maxRows * 4, value: 4 },
      { label: 'All', value: 5 }
    ]);
    const filterRef = useRef();
    const filterInput = useRef(null);
    const windowSize = useWindowSize();
    
    const order = (col) => {
        if (orderBy === col.value) {
            setOrderDir(orderDir === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setOrderBy(col.value)
            setOrderDir('ASC')
        }
    }

    const updateFilter = (e, hide) => {
        if (hide) {
            hideFilter()
        }
        let value = e.currentTarget.value
        if (e.currentTarget.type === 'number' && value) value = Number(value)
        setFilters({ ...filters, [filtering]: value })
    }

    const filterClick = (e, col) => {
        e.preventDefault();
        e.stopPropagation();
        let el = e.target
        while (el && el.nodeName !== 'TH') {
            el = el.parentElement
        }
        if (el) {
            filterRef.current = el
        }
        setFiltering(col.value)
    }

    const filterFromCol = () => {
        const col = header.find(h => h.value === filtering)
        if (!col || !filterRef.current) return setFilterUi(null)

        let content
        switch (col.type) {
            case 'number':
                content = <UILIB.TextInput className="textInput input" type="number" outerclassNname="mar-b0" value={filters[col.value]} ref={filterInput} onChange={updateFilter} focus onKeyPress={e => {
                    if (e.key === 'Enter') hideFilter()
                }} />
                break;
            case 'string':
                if (Array.isArray(col.options) && col.options.length) {
                    content = <UILIB.Select data={col.options} outerclassNname="mar-b0" value={filters[col.value]} ref={filterInput} onChange={e => updateFilter(e, true)} focus />
                } else if (col.options === 'auto') {
                    const options = Array.from(new Set(data.map(d => d[col.value].raw)))
                    options.unshift({ label: 'All', value: '' })
                    content = <UILIB.Select data={options} outerclassNname="mar-b0" value={filters[col.value]} ref={filterInput} onChange={e => updateFilter(e, true)} focus />
                } else {
                    content = <UILIB.TextInput className="textInput input" outerclassNname="mar-b0" value={filters[col.value]} ref={filterInput} onChange={updateFilter} focus onKeyPress={e => {
                        if (e.key === 'Enter') hideFilter()
                    }} />
                }
                break;
            case 'date':
                content = <UILIB.TextInput className="textInput input" type="date" outerclassNname="mar-b0" value={filters[col.value]} ref={filterInput} onChange={updateFilter} focus onKeyPress={e => {
                    if (e.key === 'Enter') hideFilter()
                }} />
                break;
            default:
                content = null
                break;
        }
        
        const viewportOffset = filterRef.current.getBoundingClientRect();
        setFilterUi(<div className="columnFilterHolderL" style={{
            top: window.scrollY + viewportOffset.top - (28 * 2) - 40,
            left: viewportOffset.left
        }}>
            <ClickAway onClickAway={hideFilter}>
                <UILIB.Paper>
                    <div className="form-group mar-b0">
                        <label>{col.label}</label>
                        {content}
                    </div>
                </UILIB.Paper>
            </ClickAway>
        </div>)
    }

    const hideFilter = () => {
        setFiltering(null);
        filterRef.current = null;
        if(enableTableRender) setTableRender(tableRender === 0 ? 1 : 0);
    }

    const clearFilter = (value) => {
        const fil = { ...filters }
        delete fil[value]
        setFilters(fil)
        if(enableTableRender) setTableRender(tableRender === 0 ? 1 : 0);
    }

    const setPageHandler = (newValue) => {
      const originalValue = structuredClone(page);
      setPage(newValue)
      if(enableTableRender && originalValue.current !== page) setTableRender(tableRender === 0 ? 1 : 0);
    }

    const handleShowRecordTotal = (event) => {
      setRecordsPageSelected(event.target.value);
      if(enableTableRender) setTableRender(tableRender === 0 ? 1 : 0);
    }

    useEffect(() => {
      if(enablePagination) setPage(tableFilterHandler.current.offset);
      if(!enablePagination) setPage(pageSelect || 0);
    }, [])

    useEffect(() => {
      if(enablePagination) {
        const found = recordsPerPage.current.find(f => Number(f.value) === Number(recordsPageSelected));
        tableFilterHandler.current = { 
          direction: orderDir, 
          headName: orderBy, 
          columnFilters: filters ? filters : null,
          offset: page === 0 ? 0 : page * tableFilterHandler.current.limit, 
          limit: found ? Number(found.label) : tableFilterHandler.current.limit, 
          totalRows: tableFilterHandler.current.totalRows, 
          filter: tableFilterHandler.current.filter 
        };
      }
    }, [orderDir, orderBy, page, maxRows, recordsPageSelected, filters])

    useEffect(() => {
        if (filtering && filterRef.current) {
            filterFromCol();
        } else {
            setFilterUi(null);
        }
        // eslint-disable-next-line
    }, [filtering, filterRef.current, filters])

    useEffect(() => {
        if (filterUi && filterInput.current) {
            filterInput.current.focus();
        }
    }, [filterUi])

    let tableData = data.slice().filter(row => {
        let pass = true;
        header.forEach(header => {
            if (filters[header.value] || filters[header.value] === 0) {
                const value = row[header.value].hasOwnProperty('raw') ? row[header.value].raw : row[header.value];
                switch (header.type) {
                    case 'number':
                        {
                            if (String(value).indexOf(String(filters[header.value])) !== 0) pass = false;
                            break;
                        }
                    case 'string':
                        if (!value || value.toLowerCase().indexOf(filters[header.value].toLowerCase()) === -1) pass = false;
                        break;
                    case 'date':
                        if (!moment(value).isSame(moment(filters[header.value]), 'day')) pass = false;
                        break;
                    default:
                        return true
                }
            }
        })
        return pass
    })


    if (sortable && orderBy) {
        const col = header.find(h => h.value === orderBy);
        if (col && col.type) {
            sortData(tableData, col.value, col.type);
        }
        if (orderDir === 'DESC') tableData.reverse();
    }

  
    const hasFilters = Object.keys(filters).filter(f => filters[f] || filters[f] === 0);
    let pagination = null;

    const found = recordsPerPage.current.find(f => Number(f.value) === Number(recordsPageSelected));
    const totalRows = enablePagination ? tableFilterHandler.current.totalRows : tableData.length;
    const maxRowsLocal = enablePagination ? tableFilterHandler.current.limit : (found ? (found.label === 'All' ? totalRows : found.label) : maxRows);
    const totalPages = Math.ceil(totalRows / maxRowsLocal);

    if ((maxRowsLocal && maxRowsLocal) < totalRows) {
        const showPages = 10
        let pagesToShow = totalPages > showPages ? showPages : totalPages
        let startPage = 0
        if (totalPages > showPages && page > (showPages / 2) - 1) {
            startPage = page - (showPages / 2)
            if (startPage + showPages > totalPages - 1) {
                startPage = totalPages - showPages
            }
        }

        if(!enablePagination) {
          tableData = tableData.slice(page * maxRowsLocal, page * maxRowsLocal + maxRowsLocal);
        }

        if(totalPages > 1) {
          pagination = <div className="text-small tableLowerContainerL paginationL "> 
            <div className={"pageFirst " + (page === 0 ? 'disabled' : '')} onClick={() => setPageHandler(0)}><span className="icon-chevron-left"></span><span className="icon-chevron-left second"></span></div>
            <div className={"pageBack " + (page === 0 ? 'disabled' : '')} onClick={() => setPageHandler(p => p > 0 ? p - 1 : p)}><span className="icon-chevron-left"></span></div>
            <div className="pages">
                {Array(pagesToShow).fill(1).map((r, i) => {
                    return <div key={'pg_' + i} className={"page " + (page === i + startPage && 'selected')} onClick={() => { setPageHandler(startPage + i); setPageSelect && setPageSelect(startPage + i) }}>{startPage + i + 1}</div>
                })}
            </div>
            <div className={"pageForward " + (page === (totalPages - 1) ? 'disabled' : '')} onClick={() => setPageHandler(p => p < totalPages - 1 ? p + 1 : p)}><span className="icon-chevron-right"></span></div>
            <div className={"pageLast " + (page === (totalPages - 1) ? 'disabled' : '')} onClick={() => setPageHandler(totalPages - 1)}><span className="icon-chevron-right"></span><span className="icon-chevron-right  second"></span></div>
        </div>}
    }

    if (autoResize) {
        height = windowSize.height - autoResizeOffset;
    }

    let classes = "mainTableL "
    if (className) classes += className

    return <Fragment>
        <div style={{ width: "100%", height: '100%', overflowX: overflowX, overflowY: overflowY }}>
          {Boolean(hasFilters.length) && <div className="filtersHolderL">
              {hasFilters.map(prop => {
                  const col = header.find(h => h.value === prop)
                  if (!col) return null
                  if (resetFilters) setFilters({})
                  return <div key={prop} className="filter">{col.label}: {filters[prop]} <div className="clear" onClick={() => clearFilter(prop)}><span className="icon-cross"></span></div></div>
              })}
          </div>}
            <TableContainer
                id="TableContainer">
                {filterUi}
                <table valign="center" className={classes} cellSpacing="0" cellPadding="0">
                      <TableHead>
                        <tr>
                            {header.map((col, index) => {
                                let width;
                                let align;

                                if (col.width) width = col.width;
                                if (col.align) align = col.align;

                                const style = { width, textAlign: align }
                                let icon, filterIcon
                                if (sortable && col.type) {
                                    style.cursor = 'pointer'
                                    if (orderBy === col.value) {
                                        if (orderDir === 'DESC') {
                                            icon = <span className="sortLabel"><span className="icon-arrow-down"></span></span>
                                        } else {
                                            icon = <span className="sortLabel"><span className="icon-arrow-up"></span></span>
                                        }
                                    }
                                    if (filter) {
                                        filterIcon = <div onClick={e => filterClick(e, col)} className={"filterIcon " + (filters[col.value] && "active")}><span className="icon-funnel"></span></div>
                                    }
                                }

                                return <th key={"th_" + index} style={style} onClick={() => order(col)}>
                                    {filterIcon}<span>{col.label}</span>{icon}
                                </th>
                            })}
                        </tr>
                      </TableHead>
                    {!loading && <tbody>
                        {tableData.map((row, rowIndex) => {
                            return <tr key={"th_" + rowIndex}>
                                {header.map((headerColumn, columnIndex) => {
                                    let align;
                                    let vAlign;
                                    let width;
                                    if (headerColumn.align) align = headerColumn.align;
                                    if (headerColumn.vAlign) vAlign = headerColumn.vAlign;
                                    if (headerColumn.width) width = headerColumn.width;
                                    var columnData = row[headerColumn.value] || ""
                                    let tdStyle = { ...headerColumn.style || {}, textAlign: align };
                                    if (width) tdStyle.width = width;
                                    if (columnData.tdStyle) {
                                        tdStyle = columnData.tdStyle;
                                    }


                                    return <td key={"td_" + rowIndex + "_" + columnIndex} style={{ ...tdStyle, height: 0 }} valign={vAlign}>
                                        {columnData.hasOwnProperty('value') ? columnData.value : columnData}
                                    </td>
                                })}
                            </tr>
                        })}
                        {!Boolean(tableData.length) && <tr>
                            <td colSpan="99" align="center">No Data Found</td>
                        </tr>}
                    </tbody>
                    }
                    {loading && <tbody><tr><td colSpan="100">
                        <UILIB.Loading type={3} />
                    </td></tr></tbody>}
                </table>
          </TableContainer>
            <div className="tableLowerContainerL">
              {pagination}
              {showRecords && <div className={"mar-r15 tableLowerContainerL totalRecordsL"}>
                {data && <p style={{ display : 'inline', marginTop: 4 }}>{tableData.length} {totalRows === 1 ? 'Record ' : 'Records '}</p>}
                {enablePaginationExtras.current && (
                  <div style={{ display: "flex" }}>
                    <p style={{ display : 'inline', marginTop: 4, paddingRight: 4, minWidth: 120 }}>{'| '} {totalRows} {' Total Records'}</p>
                    <p style={{ display : 'inline', marginTop: 4, paddingRight: 4, minWidth: 120 }}>{'| Records Per Page:'}</p>
                    <UILIB.Select 
                      style={{ marginLeft: 2, marginRight: 4, width: 50 }}
                      outerStyle={{ display: "flex" , paddingRight: overflowY === 'auto' ? 22 : 4 }}
                      name="showRecordTotalL"  
                      value={recordsPageSelected}
                      onChange={event => handleShowRecordTotal(event) } 
                      data={recordsPerPage.current} 
                    />
                  </div>
                )}
                {exportEnable && <div style={{ display : 'inline', marginTop: 0 }} className="mar-l5" ><UILIB.ExportButton style={{display : 'inline'}} onClick={() => exportData(header, tableData)} /></div>} 
              </div>}
            </div>
        </div>
    </Fragment>
}