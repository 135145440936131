import { useState } from 'react';
import { calcPageSelect, setCacheData } from '../functions';
import UILIB from 'components';


export default function PageSelect({ settings, render, setRender }) 
{

  const [renderLocal, setRenderLocal] = useState(0);

  const calculated = calcPageSelect(settings, 0);

  const setPageHandler = (newValue) => {
    settings.current = setCacheData({ ...settings.current, paging: { ...settings.current.paging, totalPages: calculated.totalPages, totalRows: calculated.totalRows, offset: settings.current.paging.limit * newValue, page: newValue } });
    setRenderLocal(!renderLocal);
    setRender(!render);
  }

  return <div className="pageSelect">
          <div className={"pageFirst  " + (settings.current.paging.page === 0 ? 'disabled' : ' clickable ')} onClick={() => setPageHandler(0)}><span className="icon-chevron-left text-xsmall"></span><span className="icon-chevron-left second text-xsmall"></span></div>
          <div className={"pageBack " + (settings.current.paging.page === 0 ? 'disabled' : ' clickable ')} onClick={() => setPageHandler(settings.current.paging.page > 0 ? settings.current.paging.page - 1 : settings.current.paging.page)}><span className="icon-chevron-left text-xsmall"></span></div>
          <div className="pages">
            {Array((calculated?.pagesToShow && Number(calculated?.pagesToShow) >= 0) ? calculated.pagesToShow : 0).fill(1).map((r, i) => {
              return <div title={calculated.startPage + i + 1} key={'pg_' + i} className={"page clickable " + (settings.current.paging.page === i + calculated.startPage && 'selected ')} onClick={() => setPageHandler(calculated.startPage + i)}><div className="number text-12">{calculated.startPage + i + 1}</div></div> })}
          </div>
          <div className={"pageForward " + (settings.current.paging.page === (calculated.totalPages - 1) ? 'disabled' : ' clickable ')} onClick={() => setPageHandler(settings.current.paging.page < calculated.totalPages - 1 ? settings.current.paging.page + 1 : settings.current.paging.page)}><span className="icon-chevron-right text-xsmall"></span></div>
          <div className={"pageLast " + (settings.current.paging.page === (calculated.totalPages - 1) ? 'disabled' : ' clickable ')} onClick={() => setPageHandler(calculated.totalPages - 1)}><span className="icon-chevron-right text-xsmall"></span><span className="icon-chevron-right  second text-xsmall"></span></div>
          <UILIB.Select disabled={calculated.totalPages === 0} outerclassNname="jumpToPage" className="jumpToPageInner" name='jumpToPage' value={Number(settings?.current?.paging?.page)} data={calculated.totalPagesSelect} onChange={(ev) => setPageHandler(Number(ev.target.value))} />
        </div>
}