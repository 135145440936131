import { useState, useEffect, useRef } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { formatCurrency, formatDateTime } from 'classes/format';
import { dateWorkingDays } from "classes/dateWorkingDays";
import { useDispatch } from 'react-redux';
import { tableContainer, actionOpenDrawer, getNotesColour } from './functions';
import DealLinkTableCell from '../../../shared/dealLink';

export default function BlockOrdersTable({ filter, theDates }) {

  const headerData = [
    { label: "#", value: "finalDealNo", align: "center", type: 'number' },
    { label: "Customer", value: "companyName", width: "500px", align: "center", type: 'string' },
    { label: "SOP Owner", value: "sopUser", align: "center", type: 'string' },
    { label: "GP", value: "gp", align: "center", type: 'number' },
    { label: "Invoice", value: "invoiced", align: "center", type: 'number' },
    { label: "Submitted", value: "dateSubmitted", align: "center", type: 'date' },
    { label: "Approved", value: "approvalDate", align: "center", type: 'date' },
    { label: "Deal Type", value: "salesType", width: "150px", align: "center", type: "string" },
    { label: "Days Active", value: "daysActive", align: "center", type: 'string' },
    { label: "Logistics Status", value: "engStatus", align: "center", type: 'string' },
    { label: "Invoice No", value: "invoiceNo", align: "center", type: 'string' },
    { label: "Invoice Date", value: "invoiceDate", align: "center", type: 'date' },
    { label: "Status", value: "status", width: "150px", align: "center", type: 'string' },
    { label: "Notes", value: "adminNotes", width: "90px", align: "center" } ];


  const dispatch = useDispatch();
  const history = useHistory();
  const [tableData, setTableData] = useState({ headerData, tableData: [] });
  const [dealsDataRaw, setDealsDataRaw] = useState([]);
  const [loading, setLoading] = useState(true);
  const filterRef = useRef(filter);
  const [render, setRender] = useState(true);

  const buildTable = () => {

    let customerTableData = dealsDataRaw;
    let tableData = [];

    if (customerTableData && customerTableData.length) {
      tableData = customerTableData.map((row) => {
        
        const b_hasNotes = Boolean(row.dealNote && row.dealNote.length);
        const notesColour = getNotesColour(row);
        const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: render, set: setRender });

        let statusTxt = "Quote/Draft";
        if (row.status === 1 && row.approved === 0) {
          statusTxt = "Awaiting Approval";
        }
        if (row.status === 1 && row.approved === 1 && !row.invoiceDate) {
          statusTxt = "Awaiting Invoice";
        }
        if (row.status === 1 && row.approved === 1 && row.invoiceDate) {
          statusTxt = "Complete";
        }
        if (row.archived === 1) {
          statusTxt = "Archived";
        }

        let engStatus = "None";
        switch (row.engStatus) {
          case 0: engStatus = "None"; break;
          case 2: engStatus = "Parts on Order"; break;
          case 3: engStatus = "In build progress"; break;
          case 4: engStatus = "On Hold"; break;
          case 5: engStatus = "Install Date Agreed"; break;
          case 6: engStatus = "Awaiting Network Form"; break;
          case 25: engStatus = "Invoice Only"; break;
          case 7: engStatus = "Software"; break;
          case 30: engStatus = "All stock in - awaiting Build"; break;
          case 15: engStatus = "Build Complete"; break;
          case 10: engStatus = "Completed"; break;
          default: engStatus = "None"; break;
        }

        let daysActive = dateWorkingDays(
          moment.utc(row.invoiceDate).utc().format("YYYY-MM-DD HH:mm:ss"),
          moment.utc(row.approvalDate).utc().format("YYYY-MM-DD HH:mm:ss"),
          0);
        let daysActiveStyle = { textAlign: "center" };
        if (daysActive >= 7) {
          daysActiveStyle.backgroundColor = "orange";
          daysActiveStyle.color = "white";
          if (daysActive >= 10) {
            daysActiveStyle.backgroundColor = "red";
          }
        }

        return {
          finalDealNo: DealLinkTableCell(row, history, '/salesHub/accountmanagerview', true, {}, false),
          companyName: { value: row.companyName, raw: row.companyName },
          sopUser: { value: row.sopUser || <div>N/A</div>, raw: row.sopUser },
          gp: { value: formatCurrency(row.gp), raw: row.gp },
          invoiced: { value: formatCurrency(row.invoiced), raw: row.invoiced },
          invoiceDate: { value: formatDateTime(row.invoiceDate, "DD/MM/YYYY HH:mm"), raw: row.invoiceDate },
          daysActive: { value: daysActive, raw: daysActive, tdStyle: daysActiveStyle },
          invoiceNo: { value: <div>{row.invoiceNo}</div>, raw: row.invoiceNo },
          dateSubmitted: { value: formatDateTime(row.dateSubmitted, "DD/MM/YYYY HH:mm"), raw: row.dateSubmitted },
          approvalDate: { value: formatDateTime(row.approvalDate, "DD/MM/YYYY HH:mm"), raw: row.approvalDate },
          salesType: { value: row.salesType || "Unknown", raw: row.salesType || "Unknown" },
          engStatus: { value: engStatus, raw: row.engStatus },
          status: { value: statusTxt, raw: statusTxt },
          adminNotes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes }
        };
      })
    }

    let final = { tableData, headerData }
    setTableData(final);
    setLoading(false);
  }

  const getData = async () => {
    setLoading(true);
    const res = await Axios.get(`/sales/deals/tableview?status=${filter.status}&startDate=${theDates.startDate}&endDate=${theDates.endDate}&justMe=true`);
    setDealsDataRaw(res.data);
  }

  useEffect(() => {
    if (filterRef.current !== filter) {
      filterRef.current = filter;
      getData();
    }
  })

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [theDates, render]);

  useEffect(() => {
    buildTable();
    // eslint-disable-next-line
  }, [dealsDataRaw])

  return <UILIB.Paper className="mar-b15 width-100">
      <UILIB.Table
        height="100%"
        className="small" 
        overflowX="auto"
        overflowY="hidden"
        loading={loading}
        header={tableData.headerData}
        data={tableData.tableData}
        sortable={true}
        filter={true}
        resetFilters={filter.resetTableFilters}
        maxRows={40}
        autoResize={false}
        exportEnable={true} />
    </UILIB.Paper>
}