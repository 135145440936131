import { useState, useRef, Fragment } from "react";
import Axios from "classes/axios";
import UILIB from "components";
import { useDispatch } from "react-redux";
import { actionOpenDrawer, getGridChip } from '../functions';


export default function DeviceLookup() {
  const headerData = [
    { label: '', value: 'id', type: 'delete', behaviorType: 'multi', filtering: false, minWidth: 30, maxWidth: 30 },
    { label: 'Serial Number', value: 'SerialNumber', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: 'Company Name', value: 'AltName', type: 'string', filtering: true, minWidth: 400, maxWidth: 400 },
    { label: 'Address 1', value: 'Address1', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: 'Address 2', value: 'Address2', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: 'County', value: 'County', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Account #", value: "Reference", type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },  
    { label: 'Service Region', value: 'ServiceRegion', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Customer Grade', value: 'Grading', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 }];

  const tablePageDefaults = { deleteEnabled: true, paging: { pages: [10, 20, 50, 100, 200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'SerialNumber', orderDir: 'ASC' } };
  const dispatch = useDispatch();
  const entityData = useRef({});
  const requestCheckbox = useRef({});
  const defaultDialogue = { count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [showDeleteDialogue, setShowDeleteDialoge] = useState(defaultDialogue);
  const [remoteRender, setRemoteRender] = useState(false);
  const [loading, setLoading] = useState(false);

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];


  const actionDeleteData = async (event) => {
    setShowDeleteDialoge({ exceptionIds: event, count: event.length, show: true });
  };

  const actionDeleteException = async () => {
    try {
      setLoading(true);
      setBannerError(defaultBanner);
      const arrayData = showDeleteDialogue.exceptionIds.map(x => Number(x));
      const filtered = entityData.current.result.filter(x => arrayData.includes(x.id));
      const result = await Axios.delete('/entities/kpaxExceptions', { data: { entity: filtered } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setShowDeleteDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setRemoteRender(!remoteRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    } finally {
      setLoading(false);
    }
  };

  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      return {
        id: { value: row.id, raw: row.id },
        SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
        AltName: { value: row.AltName, raw: row.AltName },
        Reference: { value: row.Reference, raw: row.Reference },
        Address1: { value: row.Address1, raw: row.Address1 },
        Address2: { value: row.Address2, raw: row.Address2 },
        County: { value: row.County, raw: row.County },
        ServiceRegion: { value: row.ServiceRegion, raw: row.ServiceRegion },
        Grading: { value: getGridChip('createGrading', row.Grading), raw: row.Grading }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    entityData.current = await Axios.get(`/entities/kpaxExceptions/getDeviceSummary/?&$filter=${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);

    if (!entityData.current || !entityData.current.result.length || !Array.isArray(entityData.current.result)) return false;

    let checked = {};
    if (entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x => { return checked = { ...checked, [x.id]: false } })
      requestCheckbox.current = checked;
    }

    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }

  return <Fragment>
    {showDeleteDialogue.show && <UILIB.MessageBox
      header={'Delete Exceptions'}
      text={`Click OK to DELETE the selected ${(showDeleteDialogue.count === 1) ? 'Exception' : 'Exceptions' }`}
      loading={loading}
      errored={bannerError}
      onConfirm={async () => await actionDeleteException()}
      onCancel={() => setShowDeleteDialoge({ ...showDeleteDialogue, show: false })} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container row'>
        <div className='flex-item end flex-grow-1 mar-b10'>
          <UILIB.Button 
            value="Add Exception" 
            onClick={() => { actionOpenDrawer('AddDeviceException', null, dispatch, true, { get: remoteRender, set: setRemoteRender }, '400px') }} />
        </div>
        <div className='flex-item flex-grow-1'>
          <UILIB.TableNew
            name='DeviceExceptionLookup'
            className='small'
            overflowX='auto'
            overflowY='hidden'
            header={headerData}
            deleteQuery={async (ev) => await actionDeleteData(ev)}
            remoteQuery={getTableData}
            remoteRender={[remoteRender]}
            defaultSettings={tablePageDefaults} />
        </div>
      </div>
    </UILIB.Paper>
  </Fragment>
}
