import { useState, useRef, memo, Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import Axios from 'classes/axios';
import UILIB from 'components';
import Modules from './Modules';
import moment from 'moment';
import { handleSerialClicked, setFilterVarsVoCurrentRequest, consumableChip, tableContainer, actionOpenDrawer, getManualOrderDrawerNotes, getYesNo } from '../functions';


const CurrentRequestsTable = memo(({ context, entityLookup, setEntityLookup, sendToVo, setSendToVo }) => {
  const headerData = [
    { label: '', value: 'selected', type: 'boolean', align: 'center', minWidth: 20, maxWidth: 20, filtering: false },
    { label: 'Request #', value: 'tonerID', type: 'string', filtering: true },
    { label: 'Serial Number', value: 'serialNumber', type: 'string', filtering: true },
    { label: 'Ordered', value: 'tonerDate', type: 'date', filtering: true },
    { label: 'Consumable required', value: 'tonerTypeCast', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: 'Source', value: 'fromAlertType', type: 'string', filtering: true },
    { label: 'Toner Level', value: 'tonerLevel', type: 'string', filtering: true },
    { label: 'Last Dispatched', value: 'lastDespatched', type: 'date', filtering: true },
    { label: 'Notes', value: 'notes', type: 'string', filtering: false, minWidth: 40, maxWidth: 40, filterArray: getYesNo() }];


  const dispatch = useDispatch();
  const history = useHistory();
  const mainButtonClass = 'mar-l5 mar-r5 small align-right';
  const pleaseSelect = 'Please select...';
  const snoozeDateOption = 'Date';
  const snoozeToEmptyOption = 'Days to empty';
  const tablePageDefaults = { paging: { limit: 20, offset: 0, orderBy: 'tonerDate', orderDir: 'DESC' } };
  const snoozeToOptions = [pleaseSelect, snoozeDateOption, snoozeToEmptyOption];
  const defaultDialogue = { show: false };
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [showDialogue, setShowDialoge] = useState(defaultDialogue);
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(defaultDialogue);
  const [renderRemote, setRenderRemote] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const [actionSnooze, setActionSnooze] = useState(false);
  const [snoozeTo, setSnoozeTo] = useState(pleaseSelect);
  const [snoozeToDate, setSnoozeToDate] = useState('');
  const [deleting, setDeleting] = useState(false);
  const groupCheckbox = useRef({});
  const entityData = useRef({});
  const snoozeButtonClassName = (snoozeTo === snoozeToEmptyOption || (snoozeTo === snoozeDateOption && snoozeToDate !== '')) ? 'primary' : 'primary hidden';
  const renderController = (entityLookup.serialNumber && context === 'customer') ? [renderRemote] : [entityLookup, renderRemote];
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


  const handleDeleteClicked = () => {
    setBannerError(defaultError);
    setShowDeleteDialogue({ count: Object.values(groupCheckbox.current).filter(x => x.checked).length, show: true }); 
  };

  const checkbox = async (tonerID) => {
    groupCheckbox.current = { ...groupCheckbox.current, [tonerID]: !groupCheckbox.current[tonerID] };
    setLocalRender(!localRender);
  };

  const resetSnooze = () => {
    setSnoozeTo(pleaseSelect);
    setSnoozeToDate('');
    setActionSnooze(false);
  }

  const actionSendToVo = () => { 
    const tonerIds = Object.keys(groupCheckbox.current).filter(x => groupCheckbox.current[x]);
    const serials = entityData.current.result.filter(x => tonerIds.map(m => Number(m)).includes(Number(x.tonerID))).map(x => x.serialNumber);
    const equipmentId = Array.from(new Set(entityData.current.result.filter(x => tonerIds.map(m => Number(m)).includes(Number(x.tonerID))).map(x => x.sa_inkey))).join(',');
    const serialCount = Array.from(new Set(serials)).length;
    if(serialCount > 1) {
      setShowDialoge({ ...showDialogue, show: true });
    } else {
      let consumables = {};
      const tonerIds = Object.keys(groupCheckbox.current).filter(x => groupCheckbox.current[x]).map(x => Number(x));
      tonerIds.forEach(x =>  { return consumables = { ...consumables, [x]: { quantity: 1, type: -1, contact: -1, sendEmail: false } } });
      setSendToVo({ ...sendToVo, sending: true, tonerIds: tonerIds, consumables: consumables, totalRecords: entityData.current.nonePaged, equipmentId: equipmentId });
      if(entityLookup.hasOwnProperty('serialNumber') && entityLookup.context === 'customer') {
        const { serialNumber, ...appendObject } = entityLookup;
        setEntityLookup(appendObject); }
    }
  };

  const actionDeleteRequest = async () => {
    setDeleting(true);
    try {
      const mappedArray = Object.keys(groupCheckbox.current).filter(x => groupCheckbox.current[x]).map(x => Number(x));
      const result = await Axios.delete(`/entities/tonerOrders`, { data: { entity: mappedArray.map(x => { return { tonerID: Number(x) } }) } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        entityData.current.result = entityData.current.result.filter(x => !mappedArray.includes(Number(x.tonerID)));
        groupCheckbox.current = {};
        setBannerError(defaultError);
        setShowDeleteDialogue(defaultDialogue);
        setLocalRender(!localRender);
      }
    } catch (error) {
      console.log(error);
      setBannerError({ error: true, message: exceptionError });
    }
    setDeleting(false);
  };

  
  async function constructTable(tonderData) {
    const tableData = tonderData.map(row => {

      const b_hasNotes = Boolean(row.notes && row.notes.length);
      const notesColour = (b_hasNotes) ? 'colour background-6 red' : 'colour lightGrey';
      const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });

      const dispatched = (entityData.current.salesOrder && entityData.current.salesOrder.result.length)
        ? `${formatDateTime(entityData.current.salesOrder.result[0].CompletedDate, 'DD/MM/YY')}`
        : 'N/A';
      const tonerDate = moment(row.tonerDate).utc().format("DD/MM/YYYY HH:mm:ss");

      const bgColour = Boolean(row.isManualOrder) ? 'colour background-2 purple' : '';

      return {
        selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox checked={groupCheckbox.current[row.tonerID]} type='checkbox' onChange={() => checkbox(row.tonerID)} />} /> },
        tonerID: { value: row.tonerID, raw: row.tonerID, class: bgColour },
        serialNumber: { value: handleSerialClicked(row, history, null), raw: row.serialNumber, class: bgColour },
        tonerDate: { value: tonerDate, raw: row.tonerDate, class: bgColour },
        tonerTypeCast: { value: consumableChip(row.tonerTypeCast), raw: row.tonerTypeCast },
        fromAlertType: { value: row.fromAlertType, raw: row.fromAlertType, class: bgColour },
        tonerLevel: { value: row.tonerLevel, raw: row.tonerLevel, class: bgColour },
        lastDespatched: { value: dispatched, raw: dispatched, class: bgColour },
        notes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes },
      }
    })
    
    return tableData;
  }


  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const expandArray = ['storeNote', 'itemNote', 'notes', 'stockHoldingNote'];
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const salesOrder = '&$limit=1&$offset=0&$order=Reference&$direction=DESC';
    const filterVars = setFilterVarsVoCurrentRequest();

    if(entityLookup.hasOwnProperty('customerId') && context === 'customer') {
      const customerId = entityLookup['customerId'];
      entityData.current = await Axios.get(`/entities/tonerOrders/getCurrentRequests/?&$filter=sa_cukey eq ${customerId} and ${filterVars} ${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
      const serialNumbers = entityData.current.result.map(x => x.serialNumber);
      entityData.current.salesOrder = await Axios.get(`/entities/salesOrders/getSummary/?&$filter=SerialNumber in ${serialNumbers.join(',')} and deletedDate is null ${salesOrder}`, { cancelToken: cancelToken.token }).then(api => api.data);
    }

    if(entityLookup.hasOwnProperty('serialNumber') && context === 'equipment') {
      const serialNumber = entityLookup.serialNumber;
      entityData.current = await Axios.get(`/entities/tonerOrders/getCurrentRequests/?&$filter=serialNumber eq ${serialNumber} and ${filterVars} ${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
      entityData.current.salesOrder = await Axios.get(`/entities/salesOrders/getSummary/?&$filter=SerialNumber eq ${serialNumber} and deletedDate is null ${salesOrder}`, { cancelToken: cancelToken.token }).then(api => api.data);
    }

    if(!entityData.current || ! entityData.current.result) return false;

    let checked = {};
    if(entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x => { checked = { [x.tonerID]: false, ...checked } });
      groupCheckbox.current = checked;
    }

    entityData.current.result.forEach(x => {
      if(Boolean(x.isManualOrder)) x.notes.unshift(getManualOrderDrawerNotes(x, history))
    })
    
    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    {(actionSnooze && !Object.values(groupCheckbox.current).every(x => x === false)) && <Modules.OnSnoozeClicked 
      entityData={entityData}
      groupCheckbox={groupCheckbox}
      renderRemote={renderRemote}
      setRenderRemote={setRenderRemote}
      snoozeTo={snoozeTo} 
      snoozeDateOption={snoozeDateOption} 
      snoozeToOptions={snoozeToOptions} 
      setSnoozeTo={setSnoozeTo} 
      setSnoozeToDate={setSnoozeToDate} 
      resetSnooze={resetSnooze} 
      snoozeToDate={snoozeToDate} 
      snoozeButtonClassName={snoozeButtonClassName} />}
    {showDeleteDialogue.show && <UILIB.MessageBox 
      header={'Delete Request'} 
      loading={deleting}
      text={`Click OK to DELETE the selected ${(showDeleteDialogue.count) > 1 ? 'Requests' : 'Request'}`} 
      onConfirm={async () => await actionDeleteRequest(showDeleteDialogue.requestIds)} 
      onCancel={() => setShowDeleteDialogue({ ...showDeleteDialogue, show: false })} 
      errored={bannerError} />}
    {showDialogue.show && <UILIB.MessageBox 
      showCancel={false}
      header={'Process Records'} 
      text={'You have selected multiple devices, please review and try again'} 
      onConfirm={async () => setShowDialoge({ ...showDialogue, show: false })} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container wrap mar-b10'>
        <div className='flex-item flex-grow-1 start align-center'><h3 className='mar-b0'>Current Requests</h3></div>
        <div className='flex-item end wrap'>
          <UILIB.Button disabled={!Object.keys(groupCheckbox.current).some(x => groupCheckbox.current[x])} name='Delete' value='Delete' className={mainButtonClass} onClick={() => handleDeleteClicked()} />
          <UILIB.Button disabled={!Object.keys(groupCheckbox.current).some(x => groupCheckbox.current[x])} className={mainButtonClass} name='SendToVO' value='Send To Vo' onClick={() => actionSendToVo()} />
          <UILIB.Button disabled={!Object.keys(groupCheckbox.current).some(x => groupCheckbox.current[x])} name='Snooze' value='Snooze' className={mainButtonClass} onClick={() => setActionSnooze(true)} />
        </div>
        <div className='flex-item start align-center width-100'>
          <div className='circle min-width-10 flex-grow-1 mar-r5 colour background-4 purple'/>
          <i className="flex-item start align-center text-11">Highlighted rows are manual orders, please view notes for more information</i>
        </div>
      </div>
      <UILIB.TableNew
        name='CurrentRequests'
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        remoteQuery={getTableData}
        remoteRender={renderController}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender]}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>
  </Fragment>
})
export default CurrentRequestsTable