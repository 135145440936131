import { useEffect, useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from "moment";
import { createCheckBox, createSelectInput, createDateInput } from 'classes/controls/formControls';


export default function OrderSubmitModal({ ourDeal, history, onCancel }) 
{

  const [formData, setFormData] = useState({ ...ourDeal, sopTeamMembers: [] });
  const [option, setOption] = useState({ approve1: false, approve2: false, approve3: false });
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';
  const heading = 'You are about to approved this deal, before you do please confirm the following:';


  const actionApproveOrder = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const result = await Axios.put(`/entities/workflow/deals/actionApproveDeal/${ourDeal.dealID}`, { data: formData }); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        if(result.status === 409) setBannerError({ error: true, message: versionError });
      } else {
        history.push('/sopHub');
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }

  const actionHandleTicked = async (event) => {
    setOption({ ...option, [event.target.name]: !option[event.target.name] });
  }

  const actionUpdateDeal = async (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  useEffect(() => {
    const actionAsync = async () => {
        const sopUsersResult = await Axios.get(`/helpers/getSopOwners`);
        const pleaseSelect = { label: "Select Later", value: '' };
        const sopUsersMapped = [pleaseSelect, ...sopUsersResult.data.map(r => { return { value: r.activeDirectoryObjectGuid, label: r.fullName } })];
        setFormData({ ...formData, sopTeamMembers: sopUsersMapped });
    }
    actionAsync();
  }, [])
  

  return <Fragment>
    <div className="addQuoteModalContainer">
      <div className="addQuoteModalContent flex-container row wrap" style={{ width: '30%', height: '35%' }}>
        <div className="flex-container row wrap center align-center pad-l30 pad-r30 font-weight-bold text-13">{heading}</div>
        <div className="flex-container row wrap pad-b10 pad-l30 pad-r30">
          <div className="flex-item row width-100 mar-t6 pad-l10 pad-r10">
            <div className="flex-item width-80 center align-center font-weight-bold text-11">If this is cash deal, accounts have approved.</div>
            {createCheckBox('', 'approve1', saving, option.approve1,  (ev) => actionHandleTicked(ev), 'width-20 center align-center', 'flex-item end width-100 mar-1', '', 'flex-item end')}
          </div>
          <div className="flex-item row width-100 pad-l10 pad-r10">
            <div className="flex-item width-80 center align-center font-weight-bold text-11">All documentation completed</div>
            {createCheckBox('', 'approve2', saving, option.approve2,  (ev) => actionHandleTicked(ev), 'width-20 center align-center', 'flex-item end width-100 mar-1', '', 'flex-item end')}
          </div>
          <div className="flex-item row width-100 pad-l10 pad-r10">
            <div className="flex-item width-80 center align-center font-weight-bold text-11">All costings checked</div>
            {createCheckBox('', 'approve3', saving, option.approve3,  (ev) => actionHandleTicked(ev), 'width-20 center align-center', 'flex-item end width-100 mar-1', '', 'flex-item end')}
          </div>
          <div className="flex-item row width-100 pad-l10 pad-r10">
            <b className="flex-item width-30 center align-center font-weight-bold text-11">SOP Owner</b>
            {createSelectInput('', 'activeDirectoryObjectGuidSopOwner', false, String(formData.activeDirectoryObjectGuidSopOwner), formData?.sopTeamMembers, (ev) =>  actionUpdateDeal(ev), null, 'flex-item end center align-center flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
          </div>
          <div className="flex-item row width-100 mar-b6 pad-l10 pad-r10">
            <b className="flex-item width-30 center align-center font-weight-bold text-11">Booked Date</b>
            {createDateInput('', 'bookedDate', false, moment(formData.bookedDate).utc().format('YYYY-MM-DD'), (ev) =>  actionUpdateDeal(ev), null, 'flex-item end center align-center flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
          </div>
          <div className="flex-container row end width-100">
            <UILIB.Button className='red' disabled={saving} value='Cancel' onClick={() => onCancel()} />
            <UILIB.Button className='green' disabled={saving || Object.values(option).some(x => !x)} loading={saving} value='Approve' onClick={async () => await actionApproveOrder()} />
            {bannerError.error && <div className="flex-container end wrap width-100">
              <div className="errored message">{bannerError.message}</div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
}