import UILIB from 'components';

export const pleaseSelect = 'Please select...';

export const createDateInput = (label, name, editable, entity, entityErrors = {}, changeInput) => {
  return createInput(
    label,
    editable ? (
      <div className="col-xs-8">
        <UILIB.TextInput className="textInput input" type="date" name={name} value={entity[name]} onChange={changeInput} errored={entityErrors && entityErrors[name]} />
      </div>
    ) : (
      <div className="col-xs-8">
        <label className="text-small">&gt; {entity[name]}</label>
      </div>
    )
  );
};

export const createTextInput = (label, name, editable, maxLength, entity, entityErrors = {}, changeInput) => {
  return createInput(
    label,
    <div className="col-xs-8">
      <UILIB.TextInput className="textInput input" disabled={!editable} name={name} value={entity[name]} maxLength={maxLength} onChange={changeInput} errored={entityErrors && entityErrors[name]} />
    </div>
  );
};

export const createSelectInput = (label, name, data, editable, entity, entityErrors = {}, changeInput) => {
  return createInput(
    label,
    editable ? (
      <div className="col-xs-8">
        <UILIB.Select name={name} value={entity[name]} onChange={changeInput} errored={entityErrors && entityErrors[name]} data={data} />
      </div>
    ) : (
      <div className="col-xs-8">
        <label className="text-small">&gt; {entity[name]}</label>
      </div>
    )
  );
};

export const createSelectInputRetained = (label, name, data, editable, entity, entityErrors = {}, changeInput) => {
  return createInput(
    label,
    <div className="col-xs-8">
      <UILIB.Select disabled={editable} name={name} value={entity[name]} onChange={changeInput} errored={entityErrors && entityErrors[name]} data={data} />
    </div>
  );
};

export const createMultilineTextInput = (label, name, placeholder, editable, entity, entityErrors = {}, changeInput, showCheveron, className, hideLabel) => {
  return createInput(
    label,
    editable ? (
      <UILIB.TextArea
        className={`${hideLabel ? 'flex-item width-100 height-100' : 'col-xs-8 width-100'} ${(className) ? className : ''}`}
        outerclassNname="width-100 height-100"
        name={name}
        placeholder={placeholder}
        value={entity[name]}
        onChange={changeInput}
        errored={entityErrors && entityErrors[name]}
      />
    ) : (
      <div className="col-xs-8">
        <label className="text-small">
          {showCheveron ? '&gt;' : ''} {entity[name]}
        </label>
      </div>
    ),
    hideLabel
  );
};

export const createControlInput = (label, editable, control, readonlyControl) => {
  return createInput(label, editable ? <div className="col-xs-8">{control}</div> : <div className="col-xs-8">{readonlyControl}</div>);
};

const createInput = (label, control, hideLabel) => {
  return (
    <div className="flex-item row offShade width-100 pad-b10 pad-t10">
      {!hideLabel && <div className="col-xs-4">{label}</div>}
      {control}
    </div>
  );
};

export const quickStatPanel = (title, stat, style) => {
  return (
    <div className="flex-item flex-1 flex-basis-0 around padding-4">
      <UILIB.Paper className="height-100 width-100 text-center" style={style}>
        <p className="mar-b10">{title}</p>
        <h2 className="text-center">{stat}</h2>
      </UILIB.Paper>
    </div>
  );
};

export const checkAndSet = (check, obj, item, value) => {
  if (check) {
    obj[item] = value;
    return true;
  }

  return false;
};

export const newError = (name, msg) => {
  return { name: name, option: 1, messageText: msg, error: true };
};

export const syncEntity = async (entity, tableDataRaw, key) => {
  let result = {};
  if ('deleted' in entity) {
    result = tableDataRaw;
    const index = result.map((x) => x[key]).indexOf(entity[key]);
    result.splice(index, 1);
  } else if (!tableDataRaw.find((x) => x[key] === entity[key])) {
    result = tableDataRaw;
    result.push(entity);
  } else {
    result = tableDataRaw.map((item) => {
      return item[key] === entity[key] ? entity : item;
    });
  }
  return result;
};
