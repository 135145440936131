import UILIB from 'components';
import moment from "moment";
import { setDrawer } from 'store/actions';
import { getContractStatus } from 'classes/helpers';
import Modules from './Modules';


export function setFilterVars(filters) {

  const defaults = ` ( createdAt gte ${moment().subtract(5, "year").utc().format("YYYY-MM-DD")} 
    and ( approvalDate isn null and approvalDate ne ) 
    and ( approved eq 1 and processStage gte 3 and declineSubmitted eq 0 ) ) `;
  const regions = (filters.regionIds.length) ? ` salesRegion in ${filters.regionIds.join(',')} ` : null;

  const arrayData = [defaults, regions].filter(x => x);

  if(filters.button === 'Pending') {
    return [...arrayData, ` ( ( contractStatus eq 0 or contractStatus is null ) and dateSubmittedCast gte ${moment().subtract(2, "year").utc().format("YYYY-MM-DD")} ) `].join(' and ');
  }
  if(filters.button === 'Checked') {
    return [...arrayData, ' contractStatus eq 1 '].join(' and ');
  }
  if(filters.button === 'PartiallySetup') {
    return [...arrayData, ' contractStatus eq 2 '].join(' and ');
  }
  if(filters.button === 'OnHold') {
    return [...arrayData, ' contractStatus eq 4 '].join(' and ');
  }
  if(filters.button === 'Complete') {
    return [...arrayData, ' contractStatus eq 3 '].join(' and ');
  }
  if(filters.button === 'NoContract') {
    return [...arrayData, ' contractStatus eq -1 '].join(' and ');
  }
  return arrayData.join(' and ');
}

export function tableContainer(onClick, icon, toolTip, colour, hover) {
  return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function actionOpenDrawer(type, entity, dispatch, enabled, sync, width) {
  if(!enabled) return false;
  const selector = (type, data) => {
    if(type === 'ViewNotesDrawer') return <Modules.NotesDrawer entity={entity} allowAdd={'/contractsHub'} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    if(type === 'DealFiltersDrawer') return <Modules.DealFiltersDrawer data={entity} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
  };
  dispatch(setDrawer({ 
    open: true, 
    width: width,
    content: selector(type, entity)
  }))
}

export function getDefaultFilters() {
  return { 
    queryString: null,
    button: null,
    regionIds: []
  };
}

export function getStatusChip(row) {
  if (row.status === 1 && row.approved === 1 && !row.invoiceDate) {
    return <UILIB.Chip toolTip="Awaiting Invoice" className="flex-container wrap table colour background-8 orange text-small width-100" value="Awaiting Invoice" />;
  }
  if (row.status === 1 && row.approved === 1 && row.invoiceDate) {
    return <UILIB.Chip toolTip="Invoiced" className="flex-container wrap table colour background-8 green text-small width-100" value="Invoiced" />;
  }
  if (row.archived === 1) {
    return <UILIB.Chip toolTip="Archived" className="flex-container wrap table colour background-8 purple text-small width-100" value="Archived" />;
  }
}

export function calcResetEnable(filters, defaultfilters) {
  return Boolean(JSON.stringify(defaultfilters) === JSON.stringify({ ...filters, queryString: null }));
};

export function getYesNo() {
  return [{ value: -1, label: "Select" },{ value: 0, label: "No" },{ value: 1, label: "Yes" }];
}

export function getDealRowColour(row) {
  if (row.daysActive > 7 && row.daysActive < 10) {
    return 'colour background-2 orange'
  }
  if (row.daysActive >= 10) {
    return 'colour background-2 red'
  }
}

export function getNotesColour(row) {
  if (!row.dealNote.length) {
    return 'colour lightGrey'
  }
  if(row.dealNote.some(x => (x.noteSource.trim() === 'Internal Notes' || x.noteSource.trim() === 'External Notes')) && !row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 orange'
  }
  if(row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 red'
  }
}

export function getContractSelect() {
  return getContractStatus([-1,0,1,2,3,4])
    .map(status => { return { label: status.label, value: String(status.value) } })
    .sort((a, b) => a.value - b.value);
};

export function getServiceFile(row) {
  return {
    serviceAgreementFile: (row.document.some(x => x.class === 17 || x.class === 30)) ? row.document.find(x => x.class === 17 || x.class === 30) : null, 
    serviceAddendumFile: (row.document.some(x => x.class === 33)) ? row.document.find(x => x.class === 33) : null }
};
