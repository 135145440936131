import UILIB from 'components';
import { setDrawer } from 'store/actions';
import Modules from './Modules';


export function actionOpenDrawer(type, entity, dispatch, enabled, sync, width) {
  if(!enabled) return false;
  const selector = (type, entity) => {
    if(type === 'AdCustomerException') return <Modules.AddCustomerException entity={entity} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    if(type === 'AddDeviceException') return <Modules.AddDeviceException entity={entity} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
  };
  dispatch(setDrawer({ 
    open: true, 
    width: width,
    content: selector(type, entity)
  }))
}

export function getGridChip(type, value) {
  if (type === 'createGrading' && value === 'Bronze') return <UILIB.Chip toolTip={value} className="table colour background gradingBronze text-small text-white width-100" value={value} />
  if (type === 'createGrading' && value === 'Silver') return <UILIB.Chip toolTip={value} className="table colour background gradingSilver text-small text-black width-100" value={value} />
  if (type === 'createGrading' && value === 'Gold') return <UILIB.Chip toolTip={value} className="table colour background gradingGold text-small text-black width-100" value={value} />
  if (type === 'createGrading' && value === 'Platinum') return <UILIB.Chip toolTip={value} className="table colour background gradingPlatinum text-small text-black width-100" value={value} />
  if (type === 'createGrading' && value === 'Diamond') return <UILIB.Chip toolTip={value} className="table colour background gradingDiamond text-small text-black width-100" value={value} />
}






