import { useState, useEffect, useRef, Fragment } from 'react';
import UILIB from 'components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setPagePreferences } from "store/actions";
import { getRegions } from "classes/helpers";
import Axios from 'classes/axios';
import ManualOrderContainer from './manualOrderContainer';
import moment from 'moment'
import { createMultiSelectInput } from 'classes/controls/formControls';
import { consumableChip, statusChip, tableContainer, setFilterVarsQueued, actionOpenDrawer, handleSerialClicked, getBGColour, getYesNo, checkManualOrder } from '../functions'


export default function QueuedOrdersTable() {
    const headerData = [
      { label: '', value: 'tonerID', type: 'delete', filtering: false, minWidth: 30, maxWidth: 30 },
      { label: 'Customer Name', value: 'customerName', type: 'string', filtering: true, minWidth: 370, maxWidth: 370 },
      { label: 'Model', value: 'machineName', type: 'string', filtering: true, minWidth: 250, maxWidth: 250 },
      { label: 'Serial #', value: 'serialNumber', type: 'string', filtering: true, minWidth: 130, maxWidth: 130 },
      { label: 'Order Date', value: 'tonerDate', type: 'datetime', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Requested', value: 'tonerTypeCast', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Region', value: 'name', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
      { label: 'Source', value: 'source', type: 'string', filtering: true , minWidth: 130, maxWidth: 130 },
      { label: 'Last Scanned', value: 'lastScanned', type: 'date', filtering: true , minWidth: 110, maxWidth: 110 },
      { label: "Snoozed By", value: "fullName", type: "string", filtering: true , minWidth: 130, maxWidth: 130 },
      { label: 'Status', value: 'status', type: 'string', filtering: true, minWidth: 120 },
      { label: 'Date Until', value: 'snoozeUntilDate', type: 'date', filtering: true, minWidth: 120 },
      { label: "Settings", value: "tonerBatchPeriod", type: "string", filtering: true , minWidth: 130, maxWidth: 130 },
      { label: 'Notes', value: 'notes', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
      { label: 'Alerts', value: 'alerts', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() }];

    const dispatch = useDispatch();
    const history = useHistory();
    const entityData = useRef({});
    const preferences = useSelector((state) => state.pagePreferences);
    const defaultDialogue = { tonerIds: [], count: 0, show: false };
    const defaultSnoozeDialogue = { tonerId: null, show: false };
    const [showDialogue, setShowDialoge] = useState(defaultDialogue);
    const [showSnoozeDialogue, setShowSnoozeDialoge] = useState(defaultSnoozeDialogue);
    const tablePageDefaults = { deleteEnabled: true };
    const defaultBanner = { error: false, message: '' };
    const [bannerError, setBannerError] = useState(defaultBanner);
    const [regions, setRegions] = useState([]);
    const [filter, setFilter] = useState({ button: '' });
    const [localRender, setLocalRender] = useState(false);
    const [selected, setSelected] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [processing, setProcessing] = useState(false);

    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';
    const exceptions = [400,404];


    const tableUnsnoozeRecord = async (tonerId) => {
      setBannerError(defaultBanner);
      setShowSnoozeDialoge({ tonerId: tonerId, show: true });
    };

    const tableDeleteToner = async (event) => {
      setBannerError(defaultBanner);
      setShowDialoge({ tonerIds: event, count: event.length, show: true });
    };

    const actionUnsnooze = async (tonerId) => {
      setProcessing(true);
      try {
        const tonerResult = await Axios.get(`/entities/tonerOrder/${tonerId}`).then(api => api.data);
        const baseData = { tonerID: tonerId, rowVersion: tonerResult.result[0].rowVersion, isSnoozed: 0, isSnoozedQueued: 0, tonerReleaseDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"), daysLeft: null, snoozedBy: null, snoozeUntilDate: null };
        const result = await Axios.put(`/entities/tonerOrder/${tonerId}`, { entity: baseData });
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          entityData.current.result = entityData.current.result.filter(x => Number(tonerId) !== Number(x.tonerID));
          setShowSnoozeDialoge(defaultSnoozeDialogue);
          setBannerError(defaultBanner);
          setLocalRender(!localRender);
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
      setProcessing(false);
    };

    const actionDeleteToner = async (tonerIds) => {
      setDeleting(true);
      try {
        const result = await Axios.delete(`/entities/tonerOrders/`, { data: { entity: tonerIds.map(x => { return { tonerID: x } }) } });
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          entityData.current.result = entityData.current.result.filter(x => !tonerIds.map(m => Number(m)).includes(Number(x.tonerID)));
          setShowDialoge(defaultDialogue);
          setBannerError(defaultBanner);
          setLocalRender(!localRender);
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
      setDeleting(false);
    };

    const handleButtonsChanged = (buttonName, set) => {
      const newTableFilter = { ...filter };
      if (newTableFilter.button === buttonName) {
          newTableFilter.button = '';
      } else {
          newTableFilter.button = buttonName;
      }
      set(newTableFilter);
    };

    const handleRegionChanged = (selected) => {
      dispatch(setPagePreferences({ ...preferences, consumablesHub: { ...preferences.consumablesHub, regions: [...selected] } }));
      setSelected([...selected]);
    };

    useEffect(() => {
      const actionAsync = async () => {
        const regionsResult = await getRegions();
        const regionsMapped = regionsResult.map(region => { return { label: region.name, value: region.id } })
        setRegions(regionsMapped);
      }
      actionAsync();
    }, [])

    async function constructTable(tonderData) {

        const tableData = tonderData.map(row => {
          
            const sourceData = Array.from(new Set([row.fromAlertType, row.raisedBy])).join(' - ');

            const b_hasNotes = Boolean(row.notes && row.notes.length);
            const b_hasAlerts = Boolean((row.storeNote && row.storeNote.length) || (row.itemnote && row.itemnote.length));

            const notesColour = (b_hasNotes) ? 'colour background-6 red' : 'colour lightGrey';
            const alertsColour = (b_hasAlerts) ? 'colour background-6 red' : 'colour lightGrey';

            const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });
            const fn_alerts = actionOpenDrawer.bind(null, 'ViewAlertsDrawer', row, dispatch, b_hasAlerts);

            const bgColour = getBGColour(row);
            const lastScanned = (row.lastScanned === 'NA') ? row.lastScanned : moment(row.lastScanned).utc().format("DD/MM/YYYY");

            const tonerDate = moment(row.tonerDate).utc().format("DD/MM/YYYY HH:mm:ss");

            const customerContainer = (checkManualOrder(row))
              ? <ManualOrderContainer data={row} />
              : row.customerName

            const unSnooze = (row.isSnoozedQueued)
              ? <UILIB.TableContainer data={<UILIB.Button value="Un-Snooze" className="table green" onClick={async () => await tableUnsnoozeRecord(row.tonerID)}/>} className="width-100 height-100" />
              : row.tonerBatchPeriod

            return {
              tonerID: { value: row.tonerID, raw: row.tonerID, class: bgColour },
              customerName: { value: customerContainer, raw: row.customerName, class: bgColour },
              machineName: { value: row.machineName, raw: row.machineName, class: bgColour },
              serialNumber: { value: handleSerialClicked(row, history, null), raw: row.serialNumber, class: bgColour },
              tonerDate: { value: tonerDate, raw: row.tonerDate, class: bgColour },
              tonerTypeCast: { value: consumableChip(row.tonerTypeCast), raw: row.tonerTypeCast, class: bgColour },
              tonerBatchPeriod: { value: unSnooze, raw: row.tonerBatchPeriod, class: (row.isSnoozedQueued) ? '' : bgColour },
              fullName: { value: row.fullName, raw: row.fullName, class: bgColour },
              name: { value: row.name, raw: row.name, class: bgColour },
              lastScanned: { value: lastScanned, raw: row.lastScanned, class: bgColour },
              source: { value: sourceData, raw: sourceData, class: bgColour },
              status: { value: statusChip(row.status, row, row.status), raw: row.status, class: bgColour },
              snoozeUntilDate: { value: row.snoozeUntilDate ? moment(row.snoozeUntilDate, 'YYYY-MM-DD HH:mm:ss.SSS').utc().format('DD/MM/YYYY') : 'n/a', raw: row.snoozeUntilDate, class: bgColour },
              notes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes },
              alerts: { value: tableContainer(fn_alerts, 'icon-bullhorn', (b_hasAlerts) ? 'View Alerts' : 'Alerts Unavailable', alertsColour, true), raw: b_hasAlerts }
            }
        })
        return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

      const queryLocal = (query !== null) ? query : '';
      const expandArray = ['storeNote', 'itemNote', 'notes', 'stockHoldingNote'];
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const filterButtons = setFilterVarsQueued(filter);
      const regionSelected = (selected.length) ? ` ServiceRegionId in ${selected.join(',')} ` : '';

      entityData.current = await Axios.get(`/entities/tonerOrders/getSummaryQueued/?&$filter=${[filterButtons, regionSelected].filter(x => x).join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
      
      entityData.current.result.sort((a, b) => { return b.urgentOrder - a.urgentOrder });

      if(!entityData.current || !entityData.current.result.length) return false;

      dispatch(setPagePreferences({ ...preferences, consumablesHub: { regions: preferences.consumablesHub.regions }}));

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }

    return <Fragment>
      {showSnoozeDialogue.show && <UILIB.MessageBox 
        header={'Un-Snooze Record'} 
        loading={processing}
        errored={bannerError}
        text={`Click OK to UN-SNOOZE the selected Record`} 
        onConfirm={async () => await actionUnsnooze(showSnoozeDialogue.tonerId)} 
        onCancel={() => setShowSnoozeDialoge({ ...showSnoozeDialogue, show: false })} />}
      {showDialogue.show && <UILIB.MessageBox 
        header={'Delete Records'} 
        loading={deleting}
        errored={bannerError}
        text={`Click OK to DELETE the selected ${(showDialogue.count) > 1 ? 'Records' : 'Record'}`} 
        onConfirm={async () => await actionDeleteToner(showDialogue.tonerIds)} 
        onCancel={() => setShowDialoge({ ...showDialogue, show: false })} />}
      <div className="flex-container row mar-b15 mar-l5 mar-r5 mar-t10">  
        <UILIB.Paper className='width-100 mar-b15'>
          <div className='flex-item width-100 mar-b10'>
            <div className='col-xs-6 '><h3>Queued Orders</h3></div>
          </div>
          <div className='flex-container row'>
            <div className='flex-item start flex-grow-1 align-center'> 
              <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
            </div>       
          </div>
        </UILIB.Paper>
        <UILIB.Paper className='width-100'>
            <div className='flex-container row mar-b10'>
              <div className='flex-item flex-grow-1 start wrap'>
                <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Batched' ? 'primary' : 'secondary')} name='Batched' value='Batched' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
                <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Snoozed' ? 'primary' : 'secondary')} name='Snoozed' value='Snoozed' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
              </div>
              <div className='flex-item end'>
                {createMultiSelectInput('', 'selectRegion', false, selected, regions, 'All Regions', (ev) => handleRegionChanged(ev.target.value), null, null, null, null, null)}
              </div>
            </div>
            <UILIB.TableNew
              name={`ConsumablesQueuedOrders`}
              className='small'
              overflowX='auto'
              overflowY='hidden'    
              header={headerData}
              deleteQuery={async (ev) => await tableDeleteToner(ev)}
              localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
              localRender={[localRender]}
              remoteQuery={getTableData}
              remoteRender={[selected, filter]}
              defaultSettings={tablePageDefaults} />
        </UILIB.Paper>
      </div>
    </Fragment>
}