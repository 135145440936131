import { useEffect, useState, useRef, memo, Fragment } from 'react';
import UILIB from 'components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { productOrigin, deleteProduct, updateProductEntity, calcActiveField } from '../../shared/functions';


const QuoteProductsTable = memo((props) => 
{
    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';
    const versionError = 'A newer version of this record has already been saved. Please reload the data';
    const exceptions = [400,404,409,500];

    const productCount = props.productTickedEvent.current.productCount;
    const style = { paddingLeft: 2 };
    const title = "Delete Selected";
    const timerObject = useRef({});
    const activeFields = useRef({});
    const deviceNumbers = useRef(props.ourDeal.selected);
    const [tableData, setTableData] = useState([]);

    const headerData = [
      { 
        label: productCount > 0 ? <span style={style} className="icon-trash2 height-100 width-100" title={title} alt={title} onClick={async () => await props.openDeleteMulti(props.productTickedEvent.current)}></span> : '', 
        value: "ticked",
        type: 'string' },
      { label: "Origin", value: "origin", align: "center", type: 'string' },
      { label: "Quantity", value: "qty", align: "left", type: 'number' },
      { label: "Device", value: "deviceNo", align: "left" },
      { label: "Product", value: "product", align: "left" },
      { label: "Part Number", value: "partNumber", align: "left" },
      { label: "Supplier", value: "supplier", align: "left" },
      { label: "New/Used", value: "newUsed", align: "left" },
      { label: "Address & Location", value: "location", align: "left", type: 'number', split: ['address', 'location'] },
      { label: "CPC's", value: "cpcsVols", align: "left" },
      { label: "% Discount", value: "discount", align: "left", type: 'number' },
      { label: "Cost", value: "cost", align: "left", type: 'number' },
      { label: "Sale", value: "sale", align: "left", type: 'number' },
      props.fieldWorkflowObj.scottishProcurementLease && { label: "Lease per Qtr", value: "lease", align: "left", type: 'number' },
      { label: "Hide", value: "hide", align: "center", type: 'text' },
      { label: "", value: "options", align: "center", type: 'text', dontExport: true }
    ]

    const getProducts = (comp, productDataRaw) => {

        if(!productDataRaw || !Array.isArray(productDataRaw)) return [];

        let tableData = [];

        let qtyData = [];
        let tCnt = 1;
        while (tCnt < 100) {
            qtyData.push({ label: tCnt, value: tCnt })
            tCnt++;
        }

        const productsSorted = productDataRaw.sort(function(a, b){
          if(Number(a.deviceNo) < Number(b.deviceNo)) { return -1; }
          if(Number(a.deviceNo) > Number(b.deviceNo)) { return 1; }           
          return 0; });

        productsSorted.filter(p => p.parentId === 0).forEach((row, rowIndex) => {

            const hasCompats = comp.filter(cp => cp?.partId === row?.portalPart?.id).some(c => c?.hasCompats);

            if (!row.specificDeviceLocation || row.specificDeviceLocation === "null" || row.specificDeviceLocation === "undefined") row.specificDeviceLocation = ""

            const productId = row.prodID;

            let img = "";
            if (row.portalPart) img = props?.ourDeal?.hostConfig?.cdnFtp?.host + "/files/images/sales/sopparts/" + row.portalPart.id + "/" + row.portalPart.imageName;

            let cpcsVols = <div style={{ display: "flex", alignItems: "center" }}></div>
            if (((row.portalPart && row.portalPart.SalesPartTypeId === 34) || ([1,2].includes(row.rowType) && row.isADevice === 1)) && props.ourDeal.leaseorcash !== 'Cash - Trade (SOP Only)') cpcsVols = <div>
                <div className="flex-container row start nowrap text-small">Mono CPC</div>
                <div className="flex-container row start nowrap text-small">
                    <UILIB.TextInput
                        className="textInput input"
                        disabled={!props.isActive || calcActiveField(activeFields, 'cpcMono', row.prodID) || props.cpcDisabled }
                        style={{ align: "left", width: "100%" }}
                        outerstyle={{ minWidth: '70px' }}
                        placeholder="0"
                        type="number"
                        inputMode="numeric"
                        value={row.cpcMono}
                        onChange={async (event) => await updateLocalField("cpcMono", row, rowIndex, event, 500)}
                    />p
                </div>
                <div className="flex-container row start nowrap text-small">Colour CPC</div>
                <div className="flex-container row start nowrap text-small">
                    <UILIB.TextInput
                        className="textInput input"
                        disabled={!props.isActive || calcActiveField(activeFields, 'cpcColour', row.prodID) || props.cpcDisabled }
                        style={{ align: "left", width: "100%" }}
                        outerstyle={{ minWidth: '70px' }}
                        placeholder="0"
                        type="number"
                        inputMode="numeric"
                        value={row.cpcColour}
                        onChange={async (event) => await updateLocalField("cpcColour", row, rowIndex, event, 500)}
                    />p
                </div>
            </div>

            const foundAddress = props.ourDeal.addressDropdown.find(f => Number(f.value) === Number(row.siteAddress));

            const addressLocation = <div>
                <div className="text-small">
                    <UILIB.Select
                        disabled={!props.isActive || calcActiveField(activeFields, 'siteAddress', row.prodID)}
                        className='width-100'
                        name="siteAddress"
                        data={props.ourDeal.addressDropdown}
                        value={row.siteAddress}
                        onChange={async (event) => await updateLocalField("siteAddress", row, rowIndex, event, 500)}
                    />
                </div>
                {(((row.portalPart && row.portalPart.SalesPartType && row.portalPart.SalesPartType.name === "MFP - Equipment") || ([1,2].includes(row.rowType) && row.isADevice === 1)) && props.ourDeal.leaseorcash !== 'Cash - Trade (SOP Only)') && <div>
                    <div className="text-small">
                        <UILIB.TextInput
                            className="textInput input"
                            outerclassNname='width-100'
                            disabled={!props.isActive || calcActiveField(activeFields, 'specificDeviceLocation', row.prodID)}
                            style={{ align: "left", minWidth: '120px' }}
                            outerstyle={{ width: "100%" }}
                            name="specificDeviceLocation"
                            value={row.specificDeviceLocation}
                            placeholder="Enter a location"
                            onChange={async (event) => await updateLocalField("specificDeviceLocation", row, rowIndex, event, 500)}
                        />
                    </div>
                </div>}
            </div>

            tableData.push({
                image: {
                    value: "",
                    raw: "",
                    tdStyle: { backgroundImage: `url(${img})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }
                },
                origin: {
                    value: productOrigin(row),
                    raw: row.rowType,
                    tdStyle: { backgroundImage: `url(${img})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }
                },
                ticked: {
                  value: <UILIB.Checkbox
                      disabled={!props.isActive || calcActiveField(activeFields, 'ticked', row.prodID)}
                      name="ticked"
                      checked={row.ticked}
                      onChange={async (event) => await updateLocalField("ticked", row, 'parent', event, 500)} 
                  />,
                  raw: row.ticked,
                  tdStyle: { backgroundColor: "#FFFFFF" }
                },
                product: {
                    value: <UILIB.TextInput
                        className="textInput input"
                        disabled={!props.isActive || calcActiveField(activeFields, 'description', row.prodID) || Number(row.rowType) === 4}
                        style={{ align: "left", minWidth: '280px', paddingLeft: 6 }}
                        placeholder="Enter Product Name"
                        value={row.description}
                        onChange={async (event) => await updateLocalField("description", row, rowIndex, event, 500)}
                    />,
                    raw: row.description
                },
                partNumber: {
                    value: <div style={{ padding: "0", minWidth: '100px' }}>
                        <p>{row.partNo}</p>
                    </div>,
                    raw: row.partNo
                },
                supplier: {
                    value: <UILIB.TextInput
                        className="textInput input"
                        disabled={!props.isActive || calcActiveField(activeFields, 'supplier', row.prodID)}
                        style={{ valign: "center", minWidth: '120px' }}
                        placeholder="Enter Supplier"
                        value={row.supplier}
                        onChange={async (event) => await updateLocalField("supplier", row, rowIndex, event, 500)}
                    />,
                    raw: row.supplier
                },
                productType: {
                    value: <div style={{ height: "25px", padding: "0", minWidth: '200px' }}>
                        {row.portalPart && row.portalPart.SalesPartType ? row.portalPart.SalesPartType.name : "New Part"}
                    </div>,
                    raw: row.portalPart && row.portalPart.SalesPartType ? row.portalPart.SalesPartType.name : "New Part"
                },
                newUsed: {
                    value: <UILIB.Select
                        disabled={!props.isActive || calcActiveField(activeFields, 'newUsed', row.prodID)}
                        style={{ height: "25px", padding: "0", minWidth: '80px' }}
                        data={[{ label: "New", value: "New" }, { label: "Used", value: "Used" }, { label: "Retained", value: "Retained" }]}
                        value={row.newUsed}
                        onChange={async (event) => await updateLocalField("newUsed", row, rowIndex, event, 500)}
                    />,
                    raw: row.newUsed
                },
                discount: {
                    value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>
                        <UILIB.TextInput
                            className="textInput input"
                            disabled={!props.isActive || calcActiveField(activeFields, 'discount', row.prodID)}
                            style={{ align: "left", width: '100%' }}
                            outerstyle={{ minWidth: '60px', maxWidth: '100px' }}
                            placeholder="0"
                            type="number"
                            inputMode="numeric"
                            value={Number(row.discount) === 0 ? "" : Number(row.discount)}
                            onChange={async (event) => await updateLocalField("discount", row, rowIndex, event, 500)}
                        />%
                    </div>,
                    raw: Number(row.discount) ? row.discount : 0
                },
                cost: {
                    value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>£
                        <UILIB.TextInput
                            className="textInput input"
                            disabled={!props.isActive || calcActiveField(activeFields, 'costEach', row.prodID)}
                            style={{ align: "left", minWidth: '75px', maxWidth: '100px' }}
                            placeholder="0"
                            type="number"
                            inputMode="numeric"
                            value={Number(row.costEach) === 0 ? "" : Number(row.costEach)}
                            onChange={async (event) => await updateLocalField("costEach", row, rowIndex, event, 500)}
                        />
                    </div>,
                    raw: Number(row.costEach) ? row.costEach : 0
                },
                sale: {
                    value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>£
                        <UILIB.TextInput
                            className="textInput input"
                            disabled={!props.isActive || calcActiveField(activeFields, 'priceEach', row.prodID)}
                            style={{ align: "left", minWidth: '75px', maxWidth: '100px' }}
                            placeholder="0"
                            type="number"
                            inputMode="numeric"
                            name="priceEach"
                            value={Number(row.priceEach) === 0 ? "" : Number(row.priceEach)}
                            onChange={async (event) => await updateLocalField("priceEach", row, rowIndex, event, 500)}
                        />
                    </div>,
                    raw: Number(row.priceEach) ? row.priceEach : 0
                },
                lease: {
                    value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>£
                        <UILIB.TextInput
                            className="textInput input"
                            disabled={!props.isActive || calcActiveField(activeFields, 'lease', row.prodID)}
                            style={{ align: "left", minWidth: '75px', maxWidth: '100px' }}
                            placeholder="0"
                            type="number"
                            inputMode="numeric"
                            name="lease"
                            value={Number(row.lease) === 0 ? "" : Number(row.lease)}
                            onChange={async (event) => await updateLocalField("lease", row, rowIndex, event, 500)}
                            errored={props.formErrors && props.formErrors.lease}
                        />
                    </div>,
                    raw: Number(row.lease) ? row.lease : 0
                },
                qty: {
                  value: (row.isADevice === 0 && row.parentId === 0 && row.partNo !== 'TOTALINVVAL' && Number(row.rowType) !== 4) 
                      ? <div>
                        <UILIB.NumberInput
                            className="textInput input"
                            disabled={!props.isActive}
                            placeholder="1"
                            step="1"
                            min="1"
                            allowDecimal={false}
                            value={Number(row.qty)}
                            onChange={async (event) => await updateLocalField("qty", row, rowIndex, event, 500)}
                            style={{ maxWidth: "50px" }} />
                        </div> 
                      : <div>
                        <UILIB.NumberInput
                            className="textInput input"
                            disabled={true}
                            value={Number(row.qty)}
                            allowDecimal={false} 
                            style={{ maxWidth: "50px" }}/>
                        </div>,
                  raw: row.qty
                },
                deviceNo: {
                    value: row.partNo !== "TOTALINVVAL" && <div>
                        <UILIB.Select
                            disabled={!props.isActive || calcActiveField(activeFields, 'deviceNo', row.prodID)}
                            style={{ height: "25px", padding: "0", minWidth: '40px' }}
                            name="deviceNo"
                            data={deviceNumbers.current}
                            value={Number(row.deviceNo)}
                            onChange={async (event) => await updateLocalField("deviceNo", row, rowIndex, event, 500)}
                            behaviourType={1}
                        /></div>,
                    raw: row.deviceNo
                },
                location: {  value: addressLocation, raw: [foundAddress ? foundAddress.label : '', row.specificDeviceLocation] },
                cpcsVols: { value: cpcsVols, raw: Number(row.cpcsVols) ? row.cpcsVols : 0 },
                hide: {
                    value: <div title="Hide on Invoice" alt="Hide on Invoice">
                        <UILIB.Checkbox
                            disabled={!props.isActive || calcActiveField(activeFields, 'showToCustomer', row.prodID)}
                            name="showToCustomer"
                            checked={!row.showToCustomer}
                            onChange={async (event) => await updateLocalField("showToCustomer", row, rowIndex, event, 500)}
                        />
                    </div>,
                    raw: Boolean(!row.showToCustomer),
                },
                options: {
                    value: <div>
                        {(row.isADevice === 0 && row.rowType !== 4 && props.isActive) && ((row.rowType === 1 && row.isADevice === 0) || (row.rowType !== 1 && hasCompats)) ? <div disabled={!props.isActive} className="icon icon-indent-increase mar-b5" title="Bind to Device" alt="Bind to Device" onClick={() => Object.values(activeFields.current).some(x => x) ? function () { } : props.openProductBindDrawer(row)} style={{ cursor: "pointer" }} /> : null}
                        {props.isActive && (Number(row.rowType) === 0 && row.isADevice === 1)
                            ? <div disabled={!props.isActive} className="icon icon-pencil mar-b5" title="Edit" alt="Edit" onClick={() => Object.values(activeFields.current).some(x => x) ? function () { } : props.openProductConfigurator(1, row, row.prodID, row.portalPartId)} style={{ cursor: "pointer" }} /> 
                            : null} 
                        {props.isActive && row.partNo !== "TOTALINVVAL" ? <div className="icon icon-copy mar-b5" title="Clone" alt="Clone" onClick={() => Object.values(activeFields.current).some(x => x) ? function () { } : props.openCloneDrawer(row)} style={{ cursor: "pointer" }} /> : <div></div>}
                        {props.isActive && false ? <div className="icon icon-trash2 mar-b5" title="Delete" alt="Delete" onClick={() => Object.values(activeFields.current).some(x => x) ? function () { } : deleteProduct(props, row)} style={{ cursor: "pointer" }} /> : <div></div>}
                    </div>,
                    raw: ""
                },
            })

            productDataRaw
              .filter(p => p.parentId === productId)
              .sort(function(a, b){
                if(String(a.partNo) < String(b.partNo)) { return -1; }
                if(String(a.partNo) > String(b.partNo)) { return 1; }
                return 0; })
              .forEach((child, childIndex) => {

                tableData.push({
                    qty: { 
                        value:
	                    <div>
                            <UILIB.NumberInput
                                className="textInput input"
                                disabled={true}
                                value={Number(row.qty)}
                                allowDecimal={false} 
                                style={{ maxWidth: "50px" }} />
                        </div>
                    },
                    origin: {
                      value: productOrigin(child),
                      raw: child.rowType,
                      tdStyle: { backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }
                    },
                    ticked: {
                      value: <UILIB.Checkbox
                              disabled={!props.isActive || calcActiveField(activeFields, 'ticked', child.prodID)}
                              name="ticked"
                              checked={child.ticked}
                              onChange={async (event) => await updateLocalField("ticked", child, 'child', event, 500)}
                            />,
                      raw: child.ticked,
                      tdStyle: { backgroundColor: "#FFFFFF" }
                    },
                    image: { value: "", raw: "", tdStyle: { backgroundColor: "#EEEEEE" } },
                    product: {
                        value: <div style={{ display: "flex" }}>
                            <span style={{ verticalAlign: "left", width: "10px", paddingTop: 6 }}>+</span>
                            <UILIB.TextInput
                                className="textInput input"
                                disabled={!props.isActive || calcActiveField(activeFields, 'description', child.prodID)}
                                outerstyle={{ width: '100%' }}
                                style={{ align: "left", minWidth: '280px' }}
                                placeholder="Enter Product Name"
                                value={child.description}
                                onChange={async (event) => await updateLocalField("description", child, childIndex, event, 500)}
                            />
                        </div>,
                        raw: child.description,
                        tdStyle: { backgroundColor: "#EEEEEE" }
                    },
                    partNumber: { value: child.partNo, raw: child.partNo, tdStyle: { backgroundColor: "#EEEEEE" } },
                    supplier: {
                        value: <UILIB.TextInput
                            className="textInput input"
                            disabled={!props.isActive || calcActiveField(activeFields, 'supplier', child.prodID)}
                            style={{ align: "left", minWidth: '120px' }}
                            name="supplier"
                            placeholder="Enter Supplier"
                            value={child.supplier}
                            onChange={async (event) => await updateLocalField("supplier", child, childIndex, event, 500)}
                        />,
                        raw: child.supplier,
                        tdStyle: { backgroundColor: "#EEEEEE" }
                    },
                    productType: {
                        value: <div style={{ height: "25px", padding: "0", minWidth: '200px' }}>
                            {child.portalPart && child.portalPart.SalesPartType ? child.portalPart.SalesPartType.name : ""}
                        </div>,
                        raw: child.portalPart && child.portalPart.SalesPartType ? child.portalPart.SalesPartType.name : "",
                        tdStyle: { backgroundColor: "#EEEEEE" }
                    },
                    newUsed: {
                        value: <div>
                            <UILIB.Select disabled={!props.isActive || calcActiveField(activeFields, 'newUsed', child.prodID)} style={{ height: "25px", padding: "0" }} data={[{ label: "New", value: "New" }, { label: "Used", value: "Used" }]} value={child.newUsed} onChange={async (event) => await updateLocalField("newUsed", child, childIndex, event, 500)} />
                        </div>,
                        raw: child.newUsed,
                        tdStyle: { backgroundColor: "#EEEEEE" }
                    },
                    cpcsVols: { value: "", raw: "", tdStyle: { backgroundColor: "#EEEEEE" } },
                    deviceNo: { value: "", raw: "", tdStyle: { backgroundColor: "#EEEEEE" } },
                    discount: {
                        value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>
                            <UILIB.TextInput
                                className="textInput input"
                                disabled={!props.isActive || calcActiveField(activeFields, 'discount', child.prodID)}
                                style={{ align: "left", width: '100%' }}
                                outerstyle={{ minWidth: '60px', maxWidth: '100px' }}
                                placeholder="0"
                                type="number"
                                inputMode="numeric"
                                value={Number(child.discount) === 0 ? "" : Number(child.discount)}
                                onChange={async (event) => await updateLocalField("discount", child, childIndex, event, 500)}
                            />%
                        </div>,
                        raw: child.discount,
                        tdStyle: { backgroundColor: "#EEEEEE", textAlign: "right" }
                    },
                    cost: {
                        value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>£
                            <UILIB.TextInput
                                className="textInput input"
                                disabled={!props.isActive || calcActiveField(activeFields, 'costEach', child.prodID)}
                                style={{ align: "left", minWidth: '75px', maxWidth: '100px' }}
                                placeholder="0"
                                type="number"
                                inputMode="numeric"
                                value={Number(child.costEach) === 0 ? "" : Number(child.costEach)}
                                onChange={async (event) => await updateLocalField("costEach", child, childIndex, event, 500)}
                            />
                        </div>,
                        raw: child.costEach,
                        tdStyle: { backgroundColor: "#EEEEEE", textAlign: "right" }
                    },
                    sale: {
                        value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>£
                            <UILIB.TextInput
                                className="textInput input"
                                disabled={!props.isActive || calcActiveField(activeFields, 'priceEach', child.prodID)}
                                style={{ align: "left", minWidth: '75px', maxWidth: '100px' }}
                                placeholder="0"
                                type="number"
                                inputMode="numeric"
                                name="priceEach"
                                value={Number(child.priceEach) === 0 ? "" : Number(child.priceEach)}
                                onChange={async (event) => await updateLocalField("priceEach", child, childIndex, event, 500)}
                            />
                        </div>,
                        raw: child.priceEach,
                        tdStyle: { backgroundColor: "#EEEEEE", textAlign: "right" }
                    },
                    location: { value: "", raw: "", tdStyle: { backgroundColor: "#EEEEEE", textAlign: "center" } },
                    showToCustomer: { value: "", raw: "", tdStyle: { backgroundColor: "#EEEEEE" } },
                    hide: { value: "", raw: "", tdStyle: { backgroundColor: "#EEEEEE" } },
                    options: { value: "", raw: "", tdStyle: { backgroundColor: "#EEEEEE" } }
                })
            })
        })
        setTableData(tableData);
    }


    const updateLocalField = async (field, product, dataIndex, event, timeout) => {

      try {
        let rawProduct = product;
        let discount = null;
        let costEach = null;
        var origPriceEach = null;

        if(field !== 'hide' && field !== 'ticked') {
          Object.keys(activeFields.current).forEach(x => (x === `${field}-${product.prodID}`) 
          ? activeFields.current[x] = false 
          : activeFields.current[x] = true); 
        }

        switch (field) {
            case "ticked":
                let totalTicked = 0;
                let selectedProducts = [];
                const foundChildProducts = props.ourDeal.product.filter(p => p.parentId === product.prodID);
                foundChildProducts.forEach(f => { f.ticked = event.target.checked });
                rawProduct.ticked = event.target.checked;
                props.ourDeal.product.forEach(prod => { if(prod.ticked) { totalTicked++; selectedProducts.push(prod); } });
                props.productTickedEvent.current.productCount = totalTicked;
                props.productTickedEvent.current.selectedProducts = selectedProducts;
                break;
            case "costEach":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    origPriceEach = Number(rawProduct.origPriceEach);
                    discount = event.target.value > origPriceEach ? 0 : Number(((origPriceEach - event.target.value) / origPriceEach) * 100).toFixed(2);
                    rawProduct.discount = isNaN(discount) || !isFinite(discount) ? '' : Number(discount);
                    rawProduct.costEach = isNaN(event.target.value) || !isFinite(event.target.value) ? '' : Number(event.target.value);
                 }
                else {
                    rawProduct.discount = '';
                    rawProduct.costEach = '';
                }
                break;
            case "description":
                if (event.target.value !== '') {
                    rawProduct.description = event.target.value;
                }
                else {
                    rawProduct.description = '';
                }
                break;
            case "discount":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    origPriceEach = Number(rawProduct.origPriceEach);
                    costEach = Number(origPriceEach - (origPriceEach / 100 * event.target.value)).toFixed(2);
                    rawProduct.costEach = isNaN(costEach) || !isFinite(costEach) ? '' : Number(costEach);
                    rawProduct.discount = isNaN(event.target.value) || !isFinite(event.target.value) ? '' : Number(event.target.value);
                }
                else {
                    rawProduct.costEach = '';
                    rawProduct.discount = '';
                }
                break;
            case "cpcColour":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    rawProduct.cpcColour = isNaN(event.target.value) ? '' : Number(event.target.value);
                }
                else {
                    rawProduct.cpcColour = '';
                }
                break;
            case "cpcMono":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    rawProduct.cpcMono = isNaN(event.target.value) ? '' : Number(event.target.value);
                }
                else {
                    rawProduct.cpcMono = '';
                }
                break;
            case "priceEach":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    rawProduct.priceEach = isNaN(event.target.value) ? '' : Number(event.target.value);
                }
                else {
                    rawProduct.priceEach = '';
                }
                break;
            case "qty":
                rawProduct.qty = Number(event.target.value);
                break;
            case "supplier":
                if (event.target.value !== '') {
                    rawProduct.supplier = event.target.value;
                }
                else {
                    rawProduct.supplier = '';
                }
                break;
            case "newUsed":
                if (event.target.value !== '') {
                    rawProduct.newUsed = event.target.value;
                }
                else {
                    rawProduct.newUsed = '';
                }
                break;
            case "partNo":
                if (event.target.value !== '') {
                    rawProduct.partNo = event.target.value;
                }
                else {
                    rawProduct.partNo = '';
                }
                break;
            case "deviceNo":
                rawProduct.deviceNo = Number(event.target.value);
                break;
            case "showToCustomer":
              if (event.target.type === "checkbox") {
                  rawProduct.showToCustomer = Number(!Boolean(rawProduct.showToCustomer));
                }
                break;
            case "siteAddress":
                rawProduct.siteAddress = event.target.value;
                if (event.target.value !== '') {
                    rawProduct.siteAddress = event.target.value;
                }
                else {
                    rawProduct.siteAddress = '';
                }
                break;
            case "specificDeviceLocation":
                if (event.target.value !== '') {
                    rawProduct.specificDeviceLocation = event.target.value;
                }
                else {
                    rawProduct.specificDeviceLocation = '';
                }
                break;
            case "lease":
                if (event.target.value !== '') {
                    rawProduct.lease = event.target.value;
                }
                else {
                    rawProduct.lease = '';
                }
                break;
            default:
                break;
        }

        const groupedUpdate = ['priceEach','costEach','discount'];

        getProducts(props.ourDeal.compatibles, props.ourDeal.product);

        if(field !== 'ticked') {
          if (field === 'deviceNo') {
            const response = await updateProductEntity(props, rawProduct, field, [field], `${field}-${dataIndex}`, timerObject, timeout);
            if(exceptions.includes(Number(response.status))) {  
              if(Number(response.status) === 400) toast.error(invalidConfig);
              if(Number(response.status) === 404) toast.error(notFoundError);
              if(Number(response.status) === 409) {
                props.setOurDeal({ 
                  ...props.ourDeal, 
                  product: response.result.product, 
                  selected: response.result.selected.map(x => { return { ...x, value: x.deviceNumber, label: x.deviceNumber } })
                });
                toast.error(response.message);  
              }
            } else {
              const numbers = response.result.numbers.map(x => { return { ...x, value: x.deviceNumber, label: x.deviceNumber } });
              props.setOurDeal({ ...props.ourDeal, selected: numbers });
            }
          } else if (groupedUpdate.includes(field)) {
            const response = await updateProductEntity(props, rawProduct, field, groupedUpdate, `${field}-${dataIndex}`, timerObject, timeout);
            if(exceptions.includes(Number(response.status))) {  
              if(Number(response.status) === 400) toast.error(invalidConfig);
              if(Number(response.status) === 404) toast.error(notFoundError);
              if(Number(response.status) === 409) toast.error(versionError);  
            }
          } else {
            const response = await updateProductEntity(props, rawProduct, field, [field], `${field}-${dataIndex}`, timerObject, timeout);
            if(exceptions.includes(Number(response.status))) {  
              if(Number(response.status) === 400) toast.error(invalidConfig);
              if(Number(response.status) === 404) toast.error(notFoundError);
              if(Number(response.status) === 409) toast.error(versionError);  
            }
          }
        }
      } catch (error) {
        console.log(error);
        toast.error(exceptionError);
      }

      Object.keys(activeFields.current).forEach(x => activeFields.current[x] = false);
      getProducts(props.ourDeal.compatibles, props.ourDeal.product);

    }

    useEffect(() => {
      deviceNumbers.current = props.ourDeal.selected;
      getProducts(props.ourDeal.compatibles, props.ourDeal.product);
    }, [props.refreshData, props.ourDeal, props.cpcDisabled])

    useEffect(() => {
      const calculateFields = {};
      props.ourDeal.product.forEach(x => Object.keys(x).forEach(k => calculateFields[`${k}-${x.prodID}`] = false));
      activeFields.current = calculateFields;
    }, [props.ourDeal])


    return <Fragment>
        <UILIB.Table
          className="small"
          height="100%"
          overflowX="auto"
          overflowY="hidden"
          loading={props.productsLoading}
          header={headerData}
          data={tableData}
          sortable={false}
          filter={true}
          autoResize={false}
          defaultOrderBy="finalDealNo"
          defaultOrderDir="DESC"
          exportEnable={true} />
    </Fragment>

})
export default QuoteProductsTable