import { useState, useRef, memo, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { downloadFileHandler } from 'classes/download';
import { getFileBase64 } from  'classes/helpers';
import DealLinkTableCell from '../../shared/dealLink';
import { actionOpenDrawer, tableContainer, getServiceFile, getYesNo, getDealRowColour, getNotesColour, getStatusChip, getContractSelect, calcResetEnable, setFilterVars } from '../functions';


const Orders = memo(({ permissionsObject, localPermissions, permissions, route, regions, filters, defaultfilters, setFilters, statsRender, setStatsRender }) => {

  const headerData = [
    { label: "#", value: "finalDealNo", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Customer", value: "companyName", type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Sales Person", value: "salesPersonName", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Region", value: "salesRegionTxt", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: "SOP Owner", value: "sopOwnerFullName", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Days Active", value: "daysActive", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Dept SLA", value: "deptSLA", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Service Agreement", value: "serviceAgreementFile", type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Service Addendum", value: "serviceAddendumFile", type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Status", value: "sopStatus", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: "Contract Status", value: "contractStatusTxt", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: "Notes", value: "salesNotes", filtering: false, minWidth: 35, maxWidth: 35, filterArray: getYesNo() }];


  const dispatch = useDispatch();
  const history = useHistory();
  const entityData = useRef({});
  const downloadAgreement = useRef({});
  const downloadAddendum = useRef({});
  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'finalDealNo', orderDir: 'DESC' } };
  const selectData = { regions: regions };
  const [render, setRender] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const [processing, setProcessing] = useState(false);
  const exceptions = [400,404,500];


  const handleChangeStatus = async (event, row) => {
    const contractStatus = Number(event.target.value);
    setProcessing(true);
    const dealResult = await Axios.put(`/entities/deal/${row.dealID}`, { entity: { contractStatus: contractStatus } }).then(api => api.data);
    const found = entityData.current.result.findIndex(x => Number(x.dealID) === Number(row.dealID));
    entityData.current.result[found] = { ...row, ...dealResult.result[0] };
    setProcessing(false);
    setLocalRender(!localRender);
    setStatsRender(!statsRender);
  };

  const handleMfilesDocument = async (row, context, ref) => {
    try { 
      ref.current = { ...ref.current, [row.dealID]: true };
      setLocalRender(Math.random());

      const fileObject = getServiceFile(row)[context];
      const urlAppend = `0/${fileObject.objectId}/${fileObject.objectVersion}/${fileObject.displayId}/sop`;

      const documentResult = await Axios.get(`/entities/workflow/documents/downloadDocument/${urlAppend}`); 
      if(exceptions.includes(documentResult.status)) throw new Error('UNABLE TO DOWNLOAD DOCUMENT');
      
      downloadFileHandler(fileObject.fileName, fileObject.fileExtension, documentResult.data.result);

      ref.current = { ...ref.current, [row.dealID]: false };
      setLocalRender(Math.random());
    } catch (err) {
      console.log(err)
      ref.current = { ...ref.current, [row.dealID]: false };
      setLocalRender(Math.random());
    }
  };

  const handle360Document = async (row, context, ref) => {
    try { 
      ref.current = { ...ref.current, [row.dealID]: true };
      setLocalRender(Math.random());

      const fileResult = await getFileBase64(`https://360.agilico.co.uk/serviceadmin/submitadeal/files/${context}`)
      if(!fileResult.success) throw new Error('UNABLE TO DOWNLOAD DOCUMENT');

      downloadFileHandler(row[context].split('.')[0], row[context].split('.')[1], fileResult.content);

      ref.current = { ...ref.current, [row.dealID]: false };
      setLocalRender(Math.random());
    } catch (err) {
      console.log(err)
      ref.current = { ...ref.current, [row.dealID]: false };
      setLocalRender(Math.random());
    }
  };

  const handleButtonsChanged = (buttonName) => {
    const newTableFilter = { ...filters };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = null;
    } else {
        newTableFilter.button = buttonName;
    }
    setFilters(newTableFilter);
  };

  const handleResetfilters = () => {
    setFilters(defaultfilters);
    setRender(!render);
  };


  async function constructTable(dealData) {

    const permissionDealLink = permissionsObject('orderLink', localPermissions.current.permissions, permissions, route);

    const tableData = dealData.map(row => {

      const bgColour = getDealRowColour(row);
      
      const b_hasNotes = Boolean(row.dealNote && row.dealNote.length);
      const salesNotesColour = getNotesColour(row);
      const fn_SalesNotes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender }, '400px');

      const phaseTwo = <UILIB.Chip toolTip="Phase 2" className="table colour background grey text-small width-100" value="Phase 2" />;

      const fn_agreementDownload = handleMfilesDocument.bind(null, row, 'serviceAgreementFile', downloadAgreement);
      const fn_addendumDownload = handleMfilesDocument.bind(null, row, 'serviceAddendumFile', downloadAddendum);

      const fn_agreementDownload360 = handle360Document.bind(null, row, 'serviceAgreementFile', downloadAgreement);
      const fn_addendumDownload360 = handle360Document.bind(null, row, 'serviceAddendumFile', downloadAddendum);

      const selectControl = <UILIB.Select 
        disabled={processing}
        className="tableFilter tableInput mar-b0" 
        outerclassNname="tableInput mar-b0" 
        value={String(row.contractStatus)} 
        data={getContractSelect()} 
        onChange={async (ev) => await handleChangeStatus(ev, row)} />;

      const agreementDownload = <UILIB.Button 
        className="table colour background orange width-100 text-11"
        disabled={!getServiceFile(row).serviceAgreementFile}
        name={`agreementDownload-${row.dealID}`}
        value="Download File"
        loading={downloadAgreement.current[row.dealID]} 
        onClick={(row.source === 'SOP') ? fn_agreementDownload : fn_agreementDownload360} />

      const addendumDownload = <UILIB.Button 
        className="text-11 table colour background orange width-100"
        disabled={!getServiceFile(row).serviceAddendumFile}
        name={`addendumDownload-${row.dealID}`}
        value="Download File"
        loading={downloadAddendum.current[row.dealID]} 
        onClick={(row.source === 'SOP') ? fn_addendumDownload : fn_addendumDownload360} />

      return {
        finalDealNo: DealLinkTableCell(row, history, route, permissionDealLink.entityEnable, bgColour, true, 'class'),
        companyName: { value: row.companyName, raw: row.companyName, class: bgColour },
        groupCode: { value: row.groupCode, raw: row.groupCode, class: bgColour },
        salesPersonName: { value: row.salesPersonName, raw: row.salesPersonName, class: bgColour },
        salesRegionTxt: { value: row.salesRegionTxt, raw: row.salesRegionTxt, class: bgColour },
        sopOwnerFullName: { value: row.sopOwnerFullName, raw: row.sopOwnerFullName, class: bgColour },
        daysActive: { value: row.daysActive, raw: row.daysActive, class: bgColour },
        deptSLA: { value: phaseTwo, raw: 0 },
        serviceAgreementFile: { value: agreementDownload, raw: row.serviceAgreementFile },
        serviceAddendumFile: { value: addendumDownload, raw: row.serviceAddendumFile },
        sopStatus: { value: getStatusChip(row), raw: row.sopStatus  },
        contractStatusTxt: { value: selectControl, raw: row.contractStatusTxt },
        salesNotes : { value: tableContainer(fn_SalesNotes, 'icon-pencil5', 'View Notes', salesNotesColour, true), raw: b_hasNotes }
      }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setFilterVars(filters);
    const expandArray = ['document', 'dealNote'];

    setFilters({ ...filters, queryString: `${[calcFilters].join(' and ')}${queryLocal}` });

    entityData.current = await Axios.get(`/entities/deals/getSummary/?&$filter=${[calcFilters].join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    let agreementDownloadMapped = {};
    if(entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x =>  { return agreementDownloadMapped = { ...agreementDownloadMapped, [x.dealID]: false } });
      downloadAgreement.current = agreementDownloadMapped
    }

    let addendumDownloadMapped = {};
    if(entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x =>  { return addendumDownloadMapped = { ...addendumDownloadMapped, [x.dealID]: false } });
      downloadAddendum.current = addendumDownloadMapped
    }

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    <UILIB.Paper className='width-100'>
      <div className='flex-container row mar-b10'>
        <div className='flex-item flex-grow-1 start wrap'>
          <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!filters.button || filters.button === "Pending" ? "primary" : "secondary")} name="Pending" value="Pending" onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!filters.button || filters.button === "Checked" ? "primary" : "secondary")} name="Checked" value="Checked" onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!filters.button || filters.button === "PartiallySetup" ? "primary" : "secondary")} name="PartiallySetup" value="Partially Setup" onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!filters.button || filters.button === "OnHold" ? "primary" : "secondary")} name="OnHold" value="On Hold" onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={"mar-l5 mar-r5 mar-b5 mar-t5 small " + (!filters.button || filters.button === "Complete" ? "primary" : "secondary")} name="Complete" value="Complete" onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={"mar-l5 mar-r20 mar-b5 mar-t5 small " + (!filters.button || filters.button === "NoContract" ? "primary" : "secondary")} name="NoContract" value="No Contract" onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
        </div>
        <div className='flex-item end'>
          <UILIB.Button disabled={calcResetEnable(filters, defaultfilters)} className={"mar-l20 mar-r5 small green"} name="resetFilters" value="Reset Filters" onClick={() => handleResetfilters()} />
          <UILIB.Button value="Filters" onClick={() => { actionOpenDrawer('DealFiltersDrawer', { ...selectData, filters: filters, setFilters: setFilters }, dispatch, true, { get: render, set: setRender }, '400px') }} />
        </div>
      </div>
      <UILIB.TableNew
        name={`DealsContracts`}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender, processing]}
        remoteQuery={getTableData}
        remoteRender={[filters.button, render]}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>
  </Fragment>
})
export default Orders