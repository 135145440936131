import { useRef, Fragment } from 'react';
import UILIB from 'components';


export default function SpawnMultiselectRow({ outerclassNname, className, primaryColour, secondaryColour, data, selected, setSelected, disabled }) {

  const primary = (primaryColour) ? primaryColour : ' colour background primary ';
  const secondary = (secondaryColour) ? secondaryColour : ' colour background secondary ';
  const dataArray = useRef((data && Array.isArray(data)) ? data : []);

  const handleClick = (val) => {
    setSelected({ ...selected, [val]: !selected[val] });
  };

  const spawnItem = (value, selected) => {
    let classes = "chip ";
    if (className) classes += className;
    const content = (value === undefined) ? '' : value;
    const selectedClass = (selected[value]) ? primary : secondary;
    return <div className="flex-item align-center">
      <UILIB.Button disabled={disabled} className={`${classes} ${selectedClass}`} value={content} onClick={() => handleClick(content)}>{content}</UILIB.Button>
    </div>
  };

  return <Fragment>
    <div className={outerclassNname}>
      {dataArray.current.map(x => { return spawnItem(x, selected) })}
    </div>
  </Fragment>

}