import { useState, Fragment } from 'react';
import UILIB from 'components';
import { createSelectInput, createMultiSelectInput } from 'classes/controls/formControls';


export default function AwaitingFiltersDrawer ({ data, onFinished, sync }) {

  const [filtersLocal, setFiltersLocal] = useState(data.filters);

  const updateDates = (newDate, context) => {
    filtersLocal[context][newDate.target.name] = newDate.target.value;
    setFiltersLocal({ ...filtersLocal });
  }

  const handleOptionsChanged = (event, name) => {
    if (filtersLocal[name] === event.target.name) {
      setFiltersLocal({ ...filtersLocal, [name]: '' });
    } else {
      setFiltersLocal({ ...filtersLocal, [name]: event.target.value });
    }
  };

  const handleTickedChanged = (event) => {
    filtersLocal[event.target.name].disabled = !filtersLocal[event.target.name].disabled;
    setFiltersLocal({ ...filtersLocal });
  }

  const syncAndClose = () => {
    data.setFilters({ ...data, ...data.filters = filtersLocal });
    sync.set(!sync.get);
    onFinished();
  }

  return <Fragment>         
    <h2>Filters</h2>
    <div className="mar-b15">
      <h4 className="mar-t20">Date Created</h4>
      <div className="flex-container start mar-3">
        <div className="flex-container start mar-3">
          <UILIB.Checkbox name="datesCreated" checked={!filtersLocal.datesCreated.disabled} onChange={(ev) => handleTickedChanged(ev)} />
        </div>
        <div className="flex-item start flex-grow-1">
          <UILIB.TextInput type="date" disabled={filtersLocal.datesCreated.disabled} className="textInput input" name="startDate" outerstyle={{ width: "50%" }} outerclassNname="mar-r10" value={filtersLocal.datesCreated.startDate} onChange={(ev) => updateDates(ev, 'datesCreated')} />
          <UILIB.TextInput type="date" disabled={filtersLocal.datesCreated.disabled} className="textInput input" name="endDate" outerstyle={{ width: "50%" }} outerclassNname="" value={filtersLocal.datesCreated.endDate} onChange={(ev) => updateDates(ev, 'datesCreated')} />
        </div>
      </div>
    </div>
    <div className="flex-container row start width-100 mar-3">
      {createMultiSelectInput('', 'selectRegion', false, filtersLocal.regionIds, data.regions, 'All Regions', (ev) => handleOptionsChanged(ev), null, null, null, null, null)}
      {createSelectInput('', 'selectDataSource', false, filtersLocal.staff, data.staff, (ev) => handleOptionsChanged(ev, 'staff'), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
    </div>
    <div className="flex-container row start width-100 mar-3 mar-t20">
      <UILIB.Button name="Apply Filters" value="Apply Filters" onClick={() => syncAndClose()} />
    </div>
  </Fragment>   
}
