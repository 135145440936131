import { Fragment } from 'react';
import UILIB from 'components';


export default function ViewNotes({ entity, setAddNote }) 
{  
  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className={`flex-item flex-grow-1 end`}> 
        <UILIB.Button value={"Add Notes"} className="green" onClick={() => setAddNote(true)} />
      </div>
    </div>
    <div className={`flex-container row padding-8`}>
      {Boolean(!entity.notes || !entity.notes.length) && <div className="text-14 font-weight-bold">No notes available for this order.</div>}
      {Boolean(entity.notes && entity.notes.length) && <div className="width-100">
        <div className="text-14 font-weight-bold">Notes for {entity.custName1}</div>
        {entity.notes.sort((a, b) => { return a.id - b.id }).map((note, index) => {
          const createdBy = (note.createdBy) ? note.createdBy : 'N/A';
          return <UILIB.NoteContainer key={index} type={'Notes'} createdBy={createdBy} createdAt={note.createdAt} message={note.theNotes} /> })}
      </div>}
    </div>
  </Fragment>
}