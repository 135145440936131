export default function Loading({ outerclassNname = '', className = '', colourClass, style, type = 1, message = '' }) {
    if (type === 1) {
        return (
            <div className={"flex-container row center " + outerclassNname} {...style ? style = { style } : null}>
                <div className={"lds-roller " + className}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
    if (type === 2) {
        return (
            <div className={"flex-item row center " + outerclassNname} {...style ? style = { style } : null}>
                {message}
                <div className={"lds-ellipsis " + className}>
                  <div className={colourClass}></div>
                  <div className={colourClass}></div>
                  <div className={colourClass}></div>
                  <div className={colourClass}></div>
                </div>
            </div>
        )
    }
    if (type === 3) {
        return (
            <div className={`${(outerclassNname) ? outerclassNname : 'flex-container row center width-100'}`} {...style ? style = { style } : null}>
                <div className={"lds-dual-ring" + className}></div>
            </div>
        )
    }
    if (type === 4) {
        return (
            <div className={"flex-container row center " + outerclassNname} {...style ? style = { style } : null}>
                <div className={"lds-spinner " + className}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}


