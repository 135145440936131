import { useState, useEffect, Fragment } from 'react';
import Axios from "classes/axios";
import UILIB from "components";


export default function UserEventDrawer({ entity }) {

  const [notesType, setNotesType] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    const actionAsync = async () => {
      setLoading(true);
      const response = await Axios.get(`/entities/portalsV3_userbaseCustomerEvents/?&$filter=customerId eq ${entity.id}`).then(res => res.data);
      setEvents(response?.result);
      setNotesType(Array.from(new Set(response?.result.map(x => x.eventTxt))))
      setLoading(false);
    };
    actionAsync();
  }, []);


  return <Fragment>
    <div className="flex-container row width-100 wrap mar-t20">
      {(loading || Boolean(events?.length)) && <div className="flex-item start align-center flex-grow-1 font-weight-bold text-16">View Audit Events for {entity.Name} </div>}
      {loading && <UILIB.Loading outerclassNname="flex-container row end center mar-r10" className="-table" type={3} />}
      {!loading && Boolean(!events?.length) && <h2 className="text-16 width-100">No Events available for this Customer</h2>}
    </div>
    <div className={`flex-container row padding-4`}>
      {Boolean(events?.length) && <div className="width-100">
        {notesType.map(x => {
          const filtered = events.filter(f => f.eventTxt === x);
          return <UILIB.NoteContainerGrouped type={x} data={filtered} field={'eventLog'} /> })}
      </div>}
    </div>
  </Fragment>
}
