import {Fragment } from 'react';
import UILIB from 'components';
import { formatDateTime } from "classes/format";


export default function DeviceCollect({ formData, setFormData, loading, formErrors }) 
{
  const updateFormData = (event) => {
    switch (event.target.type) {
      case "checkbox":
        setFormData({ ...formData, [event.target.name] : Number(event.target.checked) });
        break;
      case "radio":
        if (accessibilityOptions.some(option => option.name === event.target.name)) {
          setFormData({ ...formData, [event.target.name] : Number(event.target.value) });
        }
        else {
          setFormData({ ...formData, [event.target.name] : event.target.value });
        }
        break;
      default:
        setFormData({ ...formData, [event.target.name] : event.target.value });
        break;
    }
  }

  const accessibilityOptions = [
    { name: "optionStairs", label: "Stairs" },
    { name: "optionList", label: "Lift" },
    { name: "optionHelpAvl", label: "Help Available" },
    { name: "option4Man", label: "4 Man Install" },
    { name: "optionEngineerRequired", label: "Engineer Required" },
    { name: "optionEngineerNetwork", label: "Engineer will Network Device" },
    { name: "optionStairWalker", label: "Stair Walker Needed" },
  ];

  
  if (loading) return <UILIB.Loading type={3} />

  return  <Fragment>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-4 mar-b25">
                <UILIB.Paper style={{ height: "100%" }}>
                  <div className="mar-b15 mar-t5"><b>General Information</b></div>
                    <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                      <tr>
                        <td width="40%">Install Date</td>
                        <td width="60%">
                          <UILIB.TextInput
                            type="date"
                            className="textInput input"
                            name="moveDate"
                            outerclassNname=""
                            value={formatDateTime(formData.moveDate, 'YYYY-MM-DD')}
                            onChange={(event) => updateFormData(event)}
                            errored={formErrors.moveDate}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>PO Reference</td>
                        <td>{formData.dmcPONo}</td>
                      </tr>
                      <tr>
                        <td>Deal number</td>
                        <td>{formData.finalDealNo}</td>
                      </tr>
                      <tr>
                        <td>Company Name</td>
                        <td>{formData.companyName}</td>
                      </tr>
                      <tr>
                        <td>Accout Number</td>
                        <td>{formData.accountNo}</td>
                      </tr>
                      <tr>
                        <td>Sales Person</td>
                        <td>{formData.salesFullname}</td>
                      </tr>
                      <tr>
                        <td>Sales Person Email</td>
                        <td>{formData.salesEmailAddress}</td>
                      </tr>
                      <tr>
                        <td>Device Ownership</td>
                        <td>
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <input
                              type="radio"
                              name="deviceOwnership"
                              placeholder="Agilico"
                              value="Agilico"
                              checked={formData.deviceOwnership === "Agilico" ? true : false}
                              onClick={(event) => updateFormData(event)} /> Agilico
                            <input
                              type="radio"
                              name="deviceOwnership"
                              placeholder="Lease"
                              value="Lease"
                              checked={formData.deviceOwnership === "Lease" ? true : false}
                              onClick={(event) => updateFormData(event)} /> Lease
                            <input
                              type="radio"
                              name="deviceOwnership"
                              placeholder="Other"
                              value="Other"
                              checked={formData.deviceOwnership === "Other" ? true : false}
                              onClick={(event) => updateFormData(event)} /> Other
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Delivery Method</td>
                        <td>
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <input
                              type="radio"
                              name="deliveryMethod"
                              placeholder="Agilico"
                              value="Agilico"
                              checked={formData.deliveryMethod === "Agilico" ? true : false}
                              onClick={(event) => updateFormData(event)} /> Agilico
                            <input
                              type="radio"
                              name="deliveryMethod"
                              placeholder="Courier"
                              value="Courier"
                              checked={formData.deliveryMethod === "Courier" ? true : false}
                              onClick={(event) => updateFormData(event)} /> Courier
                          </div> 
                        </td>
                      </tr>
                    </table>
                  <div className="mar-b5 mar-t20 text-small"><b>Site Contact</b></div>
                    <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                      <tr>
                        <td width="40%">Order Contact Name</td>
                        <td width="60%">{formData.crm_owner && formData.crm_owner.fullname}</td>
                      </tr>
                      <tr>
                        <td>Order Contact Email</td>
                        <td>{formData.crm_owner && formData.crm_owner.internalemailaddress}</td>
                      </tr>
                      <tr>
                        <td width="40%">Install Contact Name</td>
                        <td width="60%">{formData.keyContacts1Name}</td>
                      </tr>
                      <tr>
                        <td>Install Contact Number</td>
                        <td>{formData.keyContacts1Phone}</td>
                      </tr>
                      <tr>
                        <td>Install Contact Email</td>
                        <td>{formData.keyContacts1Email}</td>
                      </tr>
                      <tr>
                        <td width="40%">IT Contact Name</td>
                        <td width="60%">{formData.keyContacts3Name}</td>
                      </tr>
                      <tr>
                        <td>IT Contact Number</td>
                        <td>{formData.keyContacts3Phone}</td>
                      </tr>
                      <tr>
                        <td>IT Contact Email</td>
                        <td>{formData.keyContacts3Email}</td>
                      </tr>
                    </table>
                  <div className="mar-b5 mar-t20 text-small"><b>Email Information</b></div>
                    <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                      <tr>
                        <td width="40%">Send Email Address</td>
                        <td width="60%">
                          <UILIB.TextInput
                            type="text"
                            className="textInput input"
                            name="emailToAddress"
                            placeholder="Send Email Address"
                            value={formData.emailToAddress}
                            onChange={(event) => updateFormData(event)}
                            errored={formErrors.emailToAddress}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Send Email Name</td>
                        <td>
                          <UILIB.TextInput
                            type="text"
                            className="textInput input"
                            name="emailToName"
                            placeholder="Send Email Name"
                            value={formData.emailToName}
                            onChange={(event) => updateFormData(event)}
                            errored={formErrors.emailToName}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Email Attachments</td>
                          <td>
                            <div className="col-xs-12 col-sm-12 col-md-12">
                              <input
                                type="radio"
                                name="emailAttachment"
                                value="0"
                                disabled={(formData.installs && formData.installs.length < 1) || (formData.removals && formData.removals.length < 1)}
                                checked={formData.emailAttachment === "0" ? true : false}
                                onClick={(event) => updateFormData(event)} /> Both
                              <input
                                type="radio"
                                name="emailAttachment"
                                value="1"
                                disabled={formData.installs && formData.installs.length < 1}
                                checked={formData.emailAttachment === "1" ? true : false}
                                onClick={(event) => updateFormData(event)} /> Install
                              <input
                                type="radio"
                                name="emailAttachment"
                                value="2"
                                disabled={formData.removals && formData.removals.length < 1}
                                checked={formData.emailAttachment === "2" ? true : false}
                                onClick={(event) => updateFormData(event)} /> Collection
                            </div>
                          </td>
                        </tr>
                    </table>
                </UILIB.Paper>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-8 mar-b25">
                <div className="col-xs-12 mar-b20" style={{ padding: "0" }}>
                  <UILIB.Paper style={{ height: "100%" }}>
                    <div className="mar-b15 mar-t5 flex-container justify-content"><b>Customer Notes</b></div>
                    <UILIB.TextArea
                      style={{ height: "140px", width: "100%", "resize": "none" }} 
                      name="customerNotes"
                      className="textInput input"
                      value={formData.customerNotes}
                      onChange={(event) => updateFormData(event)}
                    />
                  </UILIB.Paper>
                </div>
                <div className="col-xs-12 mar-b20" style={{ padding: "0" }}>
                  <UILIB.Paper style={{ height: "100%" }}>
                    <div className="mar-b15 mar-t5 flex-container justify-content"><b>Courier Notes</b></div>
                    <UILIB.TextArea
                      style={{ height: "140px", width: "100%", padding: "0", "resize": "none" }} 
                      name="courierDetails"
                      className="textInput input"
                      value={formData.courierDetails}
                      onChange={(event) => updateFormData(event)}
                    />
                  </UILIB.Paper>
                </div>
                <div className="col-xs-12" style={{ padding: "0" }}>
                  <UILIB.Paper style={{ height: "100%" }} >
                    <div className="mar-b15 mar-t5 flex-container justify-content"><b>Accessibility options</b></div>
                    <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                      {accessibilityOptions.map((option, index) => (
                        index % 2 === 0 && index + 1 < accessibilityOptions.length && (
                          <tr key={index}>
                            <td width="10%">{option.label}</td>
                            <td width="15%">
                              <div className="col-xs-12 col-sm-12 col-md-12">
                                {["Unknown", "Yes", "No"].map((value, i) => (
                                  <Fragment key={value}>
                                    <input
                                      type="radio"
                                      name={option.name}
                                      value={i === 0 ? -1 : i === 1 ? 1 : 0} 
                                      checked={(formData[option.name] === undefined || formData[option.name] === null) ? i === 0 : formData[option.name] === (i === 0 ? -1 : i === 1 ? 1 : 0)}
                                      onChange={(event) => updateFormData(event)}
                                    /> {value}{" "}
                                  </Fragment>
                                ))}
                              </div>
                            </td>
                            <td width="10%">{accessibilityOptions[index + 1].label}</td>
                            <td width="15%">
                              <div className="col-xs-12 col-sm-12 col-md-12">
                                {["Unknown", "Yes", "No"].map((value, i) => (
                                  <Fragment key={value}>
                                    <input
                                      type="radio"
                                      name={accessibilityOptions[index + 1].name}
                                      value={i === 0 ? -1 : i === 1 ? 1 : 0} 
                                      checked={(formData[accessibilityOptions[index + 1].name] === undefined || formData[accessibilityOptions[index + 1].name] === null) ? i === 0 : formData[accessibilityOptions[index + 1].name] === (i === 0 ? -1 : i === 1 ? 1 : 0)}
                                      onChange={(event) => updateFormData(event)}
                                    /> {value}{" "}
                                  </Fragment>
                                ))}
                              </div>
                            </td>
                          </tr>
                        )
                      ))}
                      <tr>
                        <td width="10%">Parking</td>
                        <td colSpan="3">
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <input
                              type="radio"
                              name="parkingOptions"
                              value="Not Required"
                              checked={formData.parkingOptions === "Not Required"}
                              onChange={(event) => updateFormData(event)}
                            /> Not Required{" "}
                            <input
                              type="radio"
                              name="parkingOptions"
                              value="On Site"
                              checked={formData.parkingOptions === "On Site"}
                              onChange={(event) => updateFormData(event)}
                            /> On Site{" "}
                            <input
                              type="radio"
                              name="parkingOptions"
                              value="Street"
                              checked={formData.parkingOptions === "Street"}
                              onChange={(event) => updateFormData(event)}
                            /> Street{" "}
                            <input
                              type="radio"
                              name="parkingOptions"
                              value="Meter"
                              checked={formData.parkingOptions === "Meter"}
                              onChange={(event) => updateFormData(event)}
                            /> Meter{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top">General Access</td>
                        <td colSpan="3">
                          <UILIB.TextArea
                            style={{ height: "150px", width: "100%", padding: "0", "resize": "none" }}
                            type="text"
                            maxLength="50"
                            value={formData.generalAccess}
                            className="textInput input"
                            name="generalAccess"
                            onChange={(event) => updateFormData(event)}
                          />
                        </td>
                      </tr>
                    </table>
                  </UILIB.Paper>
                </div>
              </div>
            </div>
            <div className="col-xs-12 mar-b25" style={{ padding: "0" }}>
              <UILIB.Paper style={{ height: "100%" }}>
                <div className="col-xs-12 mar-b20">
                  <div className="mar-b15 mar-t5 mar-b20"><label><b>Signature & Acknowledgement</b></label></div>
                  <div className="mar-b15 mar-t5 mar-l20 text-small">By e-signing this document, I confirm the movement of the above items.</div>
                </div>
                <div className="row">
                  <div style={{ width: "120px", height: "100%" }} className="mar-l20 mar-b15 mar-t15"><label>Signed Name:</label></div>
                  <div style={{ width: "300px", height: "100%" }} className="mar-l20 mar-b15 mar-t5">
                    <UILIB.TextInput
                      type="text"
                      className="textInput input"
                      name="signedName"
                      placeholder="Signed Name"
                      value={formData.signedName}
                      onChange={(event) => updateFormData(event)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div style={{ width: "120px", height: "100%" }} className="mar-l20 mar-b15 mar-t15"><label>Signed Date:</label></div>
                  <div style={{ width: "300px", height: "100%" }} className="mar-l20 mar-b15 mar-t5">
                    <UILIB.TextInput
                      type="date"
                      className="textInput input"
                      name="signedDate"
                      placeholder="Signed Date"
                      value={formData.signedDate}
                      onChange={(event) => updateFormData(event)}
                    />
                  </div>
                </div>
              </UILIB.Paper>
            </div>
          </Fragment>
}


