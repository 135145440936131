import { useEffect, useState, useRef, Fragment } from 'react';
import Axios from "classes/axios";
import UILIB from "components";
import moment from "moment";
import { StatisticsReport } from './statisticsReport';
import { getDefaultReportFilters } from '../functions';
import { createDateInput } from 'classes/controls/formControls';


export default function UserEventDrawer({ entity }) {
  
  const reportRef = useRef();
  const defaultError = { error: false, message: '' };
  const [reportData, setReportData] = useState(null);
  const [showRreport, setShowReport] = useState(false);
  const [filters, setFilters] = useState(getDefaultReportFilters());
  const [bannerError, setBannerError] = useState(defaultError);
  const [dateRangeError, setDateRangeError] = useState(defaultError);
  const [processing, setProcessing] = useState(false);

  const exceptions = [204,400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const dateSelectConstraint = 'Please choose 12 months or less';


  const actionUpdateField = (event) => {
    const incomming = { ...filters, [event.target.name]: event.target.value };
    setFilters({ ...filters, [event.target.name]: event.target.value });
    if(Number(moment(incomming.startDate).diff(incomming.endDate, 'months')) <= -12)  {
      setDateRangeError({ error: true, message: dateSelectConstraint });
    } else {
      setDateRangeError({ error: false, message: defaultError });
    }
  }

  const actionGetReportData = async () => {
    setProcessing(true);
    try { 
      const query = `?&startDate=${filters.startDate}&endDate=${filters.endDate}&reference=${entity.reference}`;
      const result = await Axios.get(`/entities/workflow/portals/actionGetReportData/${entity.customerId}/${query}`); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setReportData(result.data.result);
        setShowReport(true);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setProcessing(false);
  }

  useEffect(() => {
    setShowReport(false);
  }, [filters]);


  return <Fragment>
    {showRreport && <StatisticsReport currentUser={entity} data={reportData} dateFilters={filters} ref={reportRef} />}
    <div className="flex-container row width-100 wrap mar-t20">
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 mar-b10 width-100">Report Date Range</div>
      <div className="flex-container row start width-100">
        {createDateInput('Start Date', 'startDate', entity.deletedAt, filters.startDate, (ev) => actionUpdateField(ev), null, 'flex-item width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createDateInput('End Date', 'endDate', entity.deletedAt, filters.endDate, (ev) => actionUpdateField(ev), null, 'flex-item width-100', null, null, 'fullBorder border-colour-grey-4')}
        {dateRangeError.error && <div className="flex-container row start width-100 mar-l5">
          <div className="errored message">{dateRangeError.message}</div>
        </div>}
      </div>
      <div className="flex-container start start width-100 mar-t20">
        {!showRreport && <UILIB.Button loading={processing} disabled={(dateRangeError.error) || processing} value="Generate Report" className="button green" onClick={async () => await actionGetReportData()} />}
        {showRreport && <UILIB.Button loading={processing} disabled={(dateRangeError.error) || processing} value="Download Report" className="button green" onClick={ () => reportRef.current.handlePrint()} />}
      </div>
      {bannerError.error && <div className="flex-container row start width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>
}
