import moment from "moment";

export function validateData(data, type, regex, delimiter, message) {
  try {

    const dataTestString = (value) => {
      if(value === 'null' || value === null || typeof value === 'undefined') return false;
      return true;
    }

    const dataTestNumber = (value) => {
      if(value === 'null' || value === null || typeof value === 'undefined' || isNaN(value)) return false;
      return true;
    }

    const dataTestDate = (value) => {
      if(value === 'null' || value === null || typeof value === 'undefined' || (!moment(value, 'YYYY/MM/DD').isValid() && !moment(value, 'DD/MM/YYYY').isValid())) return false;
      return true;
    }

    const currencyConvert = (value) => {
      const newValue = value.replace(/[$£,]+/g,"");
      return parseFloat(newValue);
    }

    if(regex) {
      if(type === 'string' && dataTestString(data) && regexValidation(regex, data)) return String(data.replace(/'|,/g, ""));
      if(type === 'date' && dataTestDate(data)) return buildValidDate(data, delimiter);
      if(type === 'number' && dataTestNumber(data) && regexValidation(regex, data))  return Number(data);
    }
    if(!regex) {
      if(type === 'string' && dataTestString(data)) return String(data.replace(/'|,/g, " "));
      if(type === 'date') return buildValidDate(data, delimiter);
      if(type === 'number' && dataTestNumber(data)) return Number(data);
      if(type === 'currency' && dataTestString(data)) return currencyConvert(data);
    } 
    return '';

  }
  catch (error) {
    console.log(error);
    return '';
  }
}

export function regexValidation(key, value) {
  const regexObj = {
    specialCharacters: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    postcodePattern: /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
    alphaSpaceSpecialPattern: /^[a-zA-Z\s &-/]*$/,
    alphaSpacePattern: /^[a-zA-Z\s]*$/,
    namePattern: /^[a-zA-Z '.-]*$/,
    alphaPattern: /^[a-zA-Z]*$/,
    alphaNumericPattern: /^[a-zA-Z0-9]*$/,
    alphaNumericSpacePattern: /^[a-zA-Z0-9\s]*$/,
    alphaNumericSpaceSpecialPattern: /^[a-zA-Z0-9\s -.]*$/,
    numericPattern: /^[0-9]*$/,
    numericSpecialPattern: /^[0-9 ,.]*$/,
    mobilePattern: /^[0-9\s]*$/,
    currencyPattern: /^(?=(?:,?\d){1,99}(?:\.|$))\d{1,3}(?:,\d{3})*(?:\.\d{2,10})?$/,
    numberOnlyPattern: /^[0-9]*$/,
    yyyymmdd: /^(?:(?:(?:(?:(?:[1-9]\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:[2468][048]|[13579][26])00))(\/|-|\.)(?:0?2\1(?:29)))|(?:(?:[1-9]\d{3})(\/|-|\.)(?:(?:(?:0?[13578]|1[02])\2(?:31))|(?:(?:0?[13-9]|1[0-2])\2(?:29|30))|(?:(?:0?[1-9])|(?:1[0-2]))\2(?:0?[1-9]|1\d|2[0-8])))))$/,
    ddmmyyyy: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  }
  if(!key || !value) return false;
  return regexObj[key].test(value);
}

export function buildValidDate(value, delimiter) {

  let newDate = null;

  if(Number(value.split(' ').length) === 2) {
  
    const time = value.split(' ')[1];
    const dateScraped = value.split(' ')[0];

    if(regexValidation('yyyymmdd', dateScraped.trim())) {
      newDate = dateScraped.split(delimiter);
      return [newDate[0], newDate[1], newDate[2]].join('-').concat(` ${time}`);
    }
    if(regexValidation('ddmmyyyy', dateScraped.trim())) {
      newDate = dateScraped.split(delimiter);
      return [newDate[2], newDate[1], newDate[0]].join('-').concat(` ${time}`);
    }
  }
  
  if(Number(value.split(' ').length) < 2) {

    if(regexValidation('yyyymmdd', value.trim())) {
      newDate = value.split(delimiter);
      return [newDate[0], newDate[1], newDate[2]].join('-');
    }
    if(regexValidation('ddmmyyyy', value.trim())) {
      newDate = value.split(delimiter);
      return [newDate[2], newDate[1], newDate[0]].join('-');
    }
  }

  return newDate;
}

export function formatAddress(address) {
  const { fromCRMId, id, dealId, telephone, telNo, county, customerName, accountNumber, createdAt, updatedAt, addressType, DeviceInstallationId, ...addressObj } = address;
  return addressObj ? Object.values(addressObj).filter(f => f && f.length).map(val => { return val }).join(', ') : '';
}