import { useState, Fragment } from 'react';
import UILIB from 'components';
import { getCompaniesExceptions } from 'classes/helpers';
import { getGridChip } from '../functions';
import Axios from "classes/axios";


export default function AddCustomerException ({ onClose, sync }) {

  const defaultState = { 
    ShowOverview: false,
    customerId: undefined,
    Devices: 0,
    Name: "", 
    Reference: "", 
    Address1: "", 
    Address2: "", 
    County: "", 
    ServiceRegion: "",
    Grading: "" 
  }

  const [loading, setLoading] = useState(false);
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [formData, setFormData] = useState(defaultState);

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];


  const syncAndClose = () => {
    sync.set(!sync.get);
    onClose();
  }

  const actionCleardown = () => {
    setFormData(defaultState);
  }

  const selectCustomer = (ev) => {
    setFormData({ ...formData, ...ev, ShowOverview: true });
  }

  const actionAddException = async () => {
    try {
      setLoading(true);
      const result = await Axios.post('/entities/kpaxException', { entity: { customerId: formData.customerId } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setBannerError(defaultBanner);
        syncAndClose();
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    } finally {
      setLoading(false);
    }
  };

  return <Fragment>         
    <div className="flex-container row width-100">
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 mar-b10 width-100">Add Exception for a Customer</div>
      <div className={`flex-item flex-grow-1 max-height-55-px pad-b20`}>
        <div className={`flex-container column width-100 mar-5 max-height-30-px`}>
          <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2`}>Search for Customer</b>
          <UILIB.AutoComplete 
            className='width-100 fullBorder border-colour-grey-4' 
            disabled={false} 
            enableButton={false} 
            name='customerSearch' 
            query={getCompaniesExceptions} 
            onClearDown={actionCleardown}
            onClick={selectCustomer} />
        </div>
      </div>
      <div className='mar-l5 mar-b20'>
        <i className="flex-item start text-11">Note, search results automatically filter out any records that have already been added as an exception</i>
      </div>
      {formData.ShowOverview && (
        <Fragment>
          <div className="flex-item center align-center font-weight-bold text-14 mar-t20 mar-b10 width-100">Selected Overview</div>
          <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
            <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">Customer Name:</p>
            <p className="flex-item start flex-basis-60 text-13">{formData.Name}</p>
          </div>
          <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
            <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">Address 1:</p>
            <p className="flex-item start flex-basis-60 text-13">{formData.Address1}</p>
          </div>
          <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
            <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">Address 2:</p>
            <p className="flex-item start  flex-basis-60 text-13">{formData.Address2}</p>
          </div>
          <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
            <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">County:</p>
            <p className="flex-item start flex-basis-60 text-13">{formData.County}</p>
          </div>
          <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
            <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">Account Number:</p>
            <p className="flex-item start flex-basis-60 text-13">{formData.Reference}</p>
          </div>
          <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
            <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20 width-40">Service Region:</p>
            <p className="flex-item start flex-basis-60 text-13">{formData.ServiceRegion}</p>
          </div>
          <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
            <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">Total Devices:</p>
            <p className="flex-item start flex-basis-60 text-13">{formData.Devices}</p>
          </div>
          <div className="flex-container row width-100 colour background-4 lightGrey mar-b20">
            <p className="flex-item start flex-basis-40  text-13 pad-l5 pad-r20">Grading:</p>
            <p className="flex-item start flex-basis-60 text-13">{getGridChip('createGrading', formData.Grading)}</p>
          </div>
          <div className='flex-container row start width-100'>
            <UILIB.Button 
              className='green' 
              disabled={loading} 
              loading={loading} 
              value='Add Exception' 
              onClick={async () => await actionAddException()} />
          </div>
          {bannerError.error && <div className="flex-item start width-100">
            <div className="errored message">{bannerError.message}</div>
          </div>}
        </Fragment>
      )}
    </div>
  </Fragment>   
}
