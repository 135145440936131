import { useState, useRef, useEffect, Fragment } from 'react';
import { exportData, toastContainer, tableSortHandlerBase } from '../../shared/functions';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';
import moment from 'moment';
import UILIB from 'components';
import Axios from 'classes/axios';
import FleetMenu from '../../shared/fleetMenu';
import DrawCRUD from './driverCRUD';
import DocumentsModal from '../documents/modal/documentsModal';

export default function DriverTable(props) {

    //exports
    const [exporting, setExporting] = useState({ 
      name: 'Export All', 
      exportUrl: '/fleet/drivers/export', 
      fileName: `Driver_Export_${moment().utc().format('YYYYMMDD-HHMMss')}`, 
      setExporting: false 
    });
 
    //documents
    const [showDocumentsModal, setShowDocumentsModal] = useState({ show: false, entity: null });

    //Specific to entity
    const uploadTableSortHandler = useRef({ ...tableSortHandlerBase });
    const entityName = 'Driver';
    const entityUrl = `/fleet/drivers`;
    const entityAddText = 'Add Driver';
    const drawWidth = '700px';
    const headerData = [
      { label: 'Id', value: 'id', type: 'number' },
      { label: 'First Name', value: 'firstName', type: 'string' },
      { label: 'Last Name', value: 'lastName', type: 'string' },
      { label: 'Role Grade', value: 'roleGrade', type: 'string' },
      { label: 'Driver Type', value: 'driverType', type: 'string' },
      { label: 'Work Email', value: 'workEmail', type: 'string' },
      { label: 'Managers Email', value: 'managersEmail', type: 'string' },
      { label: 'Job Role', value: 'jobRole', type: 'string' },
      { label: 'Department', value: 'department', type: 'string' },
      { label: 'Region', value: 'region', type: 'string' },
      { label: 'Office', value: 'office', type: 'string' },
      { label: 'Documents', value: 'documents', type: 'number', width: '110px' },
      { label: '', value: 'delete' } ];

    async function getTableData(entityData) {
      let tableData = [];
      if (entityData && entityData.length) {
        tableData = await Promise.all(entityData.map(async entity => {
          const link = <UILIB.Button value={entity.id} onClick={() => drawCRUD({ type: 'edit', entity: entity })} className='secondary table text-small width-100' />
          const documentCount = await Axios.get(`/fleet/documents/${entityName}/count?documentId=${entity.documentIdentity}`);
          return {
            id: { value: link, raw: entity.id },
            firstName: { value: entity.firstName, raw: entity.firstName },
            lastName: { value: entity.lastName, raw: entity.lastName },
            roleGrade: { value: entity.roleGrade, raw: entity.roleGrade },
            driverType: { value: entity.driverType, raw: entity.driverType },
            workEmail: { value: entity.workEmail, raw: entity.workEmail },
            managersEmail: { value: entity.managersEmail, raw: entity.managersEmail },
            jobRole: { value: entity.jobRole, raw: entity.jobRole },
            department: { value: entity.department, raw: entity.department },
            region: { value: entity.region, raw: entity.region },
            office: { value: entity.office, raw: entity.office },
            documents: { value: <UILIB.Button className='secondary table text-small width-100' value={`View (${documentCount.data.result})`} onClick={() => setShowDocumentsModal({ show: true, entity: entity })} />, raw: documentCount.data.result },
            delete: { value: <div onClick={() => drawCRUD({ type: 'delete', entity: entity })} className={'icon-trash2 pointer center text-primary mar-t5'} /> }
          }
        }));
      }
  
      setTableData(tableData);
    }
  
    //Generic from here on down
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const tableDataRaw = useRef([]);
  
    const drawCRUD = (data) => {
      dispatch(setDrawer({ open: true, content: <DrawCRUD data={data} sync={syncEntity} />, width: drawWidth }));
    }
  
    const syncEntity = async (entity) => {
      let result = {};
      if ('deleted' in entity) {
        result = tableDataRaw.current;
        const index = result.map(x => x.id).indexOf(entity.id);
        result.splice(index, 1);
        } else if (!tableDataRaw.current.find(x => x.id === entity.id)) {
        result = tableDataRaw.current;
        result.push(entity);
      } else {
        result = tableDataRaw.current.map((item) => { return item.id === entity.id ? entity : item });
      }
  
      tableDataRaw.current = result;
      await getTableData(result);
    }
  
    useEffect(() => {    
      const actionAsync = async () => { 
        setLoadingData(true);
        await props.permissions.generalAccess();
        const entities = await Axios.get(entityUrl);
        //TODO -- pagination
        //const entities = await Axios.get(`${entityUrl}&tableHandler=${encodeURIComponent(entities.data.uploadTableSortHandler)}`);
        //uploadTableSortHandler.current = { ...entities.data.uploadTableSortHandler, totalRows: entities.data.totalRows };
        tableDataRaw.current = entities.data.result;
        await getTableData(entities.data.result);
        setLoadingData(false);
      }
  
      actionAsync();
    }, [])
  
    return (
      <Fragment>
        <FleetMenu selectedButton={entityName} />
        {toastContainer(1000, 300)}
        <UILIB.Paper className='mar-l10 mar-r10'>
          <UILIB.Button className='mar-b10' value={entityAddText} onClick={() => drawCRUD({ type: 'add', entity: {}})} />
          <UILIB.Button className='mar-l10 mar-b10 secondary' disabled={!tableDataRaw.current.length} value={exporting.name} onClick={async () => await exportData(exporting, setExporting)} />
          <UILIB.Table
            height='100%'
            overflowX='auto'
            overflowY='hidden'
            loading={loadingData}
            header={headerData}
            data={tableData}
            sortable={true}
            filter={true}
            resetFilters={false}
            goExport={false}
            maxRows={40}
            autoResize={false}
            exportEnable={true} />
        {showDocumentsModal.show && (
          <DocumentsModal 
            showDocumentsModal={showDocumentsModal}
            setShowDocumentsModal={setShowDocumentsModal}
            entity={showDocumentsModal.entity}
            entityTitle={showDocumentsModal.entity.firstName + ' ' + showDocumentsModal.entity.lastName}
            entityName={entityName}
          />)}
        </UILIB.Paper>
      </Fragment> 
    );
}