import { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import UILIB from 'components';

export default function NewDeviceMovement({ formData, setFormData, loading, logisticsUsers, formErrors, submitForm, submitted }) {

    const [moveDate] = useState(true);
    const history = useHistory();


    const updateQuoteData = (event) => {
      const dataNew = {...formData};
      dataNew.quote[0][event.target.name] = event.target.value
      setFormData(dataNew)
    }

    if (loading) return <UILIB.Loading type={3} />
    
    return <Fragment>
              <div className="row">
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-4 mar-b25">
                        <UILIB.Paper style={{ height: "100%" }}>
                          <div className="mar-b15 mar-t5"><b>General Information:</b></div>
                          <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                            <tr>
                              <td width="40%">Requires Move Date?</td>
                              <td width="60%">
                                <UILIB.Toggle 
                                    checked={!formData.chargeable ? true : moveDate} 
                                    value={formData.requiresMoveDate}
                                    name="requiresMoveDate" 
                                    label="Requires Move Date?" 
                                    disabled={true} 
                                />
                              </td>
                            </tr>
                            {formData.requiresMoveDate &&
                            <tr>
                              <td >Movement Date</td>
                              <td >
                                  <UILIB.TextInput
                                    type="date"
                                    className="textInput input"
                                    name="moveDate"
                                    value={formData.moveDate}
                                    disabled={true} 
                                  />
                                
                              </td>
                            </tr>}
                            <tr>
                              <td>PO Reference</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="purchaseReference"
                                  placeholder="Purchase Order Ref"
                                  value={formData.purchaseReference}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Company Name</td>
                              <td>
                                <UILIB.TextInput
                                  className="textInput input"
                                  value={formData.companyName}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Company Email</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="companyEmail"
                                  placeholder="Company Email"
                                  value={formData.companyEmail}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Company Contact Name</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="companyContactName"
                                  placeholder="Company Contact Name"
                                  value={formData.companyContactName}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Company Telephone</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="companyTelephone"
                                  placeholder="Company Telephone"
                                  value={formData.companyTelephone}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Logistics Point of Contact</td>
                              <td>
                                <UILIB.Select 
                                  data={logisticsUsers} 
                                  name="logisticsPoCId"
                                  value={formData.logisticsPoCId}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Sales Person</td>
                              <td>
                                <UILIB.TextInput
                                  className="textInput input"
                                  name="salesPerson"
                                  value={formData.salesPerson}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Delivery Method</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="delMethod"
                                  placeholder="Delivery Method"
                                  value={formData.delMethod}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Courier</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="courier"
                                  placeholder="Courier"
                                  value={formData.courier}
                                  disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Parking</td>
                              <td>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                  <input
                                    type="radio"
                                    name="parkingOptions"
                                    placeholder="Not Required"
                                    value="Not Required"
                                    checked={formData.parkingOptions === "Not Required" ? true : false}
                                    disabled={true}  /> Not Required
                                  <input
                                    type="radio"
                                    name="parkingOptions"
                                    placeholder="On Site"
                                    value="On Site"
                                    checked={formData.parkingOptions === "On Site" ? true : false}
                                    disabled={true}  /> On Site
                                  <input
                                    type="radio"
                                    name="parkingOptions"
                                    placeholder="Street"
                                    value="Street"
                                    checked={formData.parkingOptions === "Street" ? true : false}
                                    disabled={true}  /> Street
                                  <input
                                    type="radio"
                                    name="parkingOptions"
                                    placeholder="Meter"
                                    value="Meter"
                                    checked={formData.parkingOptions === "Meter" ? true : false}
                                    disabled={true} /> Meter
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Print Management System?</td>
                              <td>
                                <UILIB.Checkbox
                                    name="printManagerSystem"
                                    checked={formData.printManagerSystem}
                                    disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Decomission Required?</td>
                              <td>
                                <UILIB.Checkbox
                                    name="decomRequired"
                                    checked={formData.decomRequired}
                                    disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Machine(s) to be taken off service?</td>
                              <td>
                                <UILIB.Checkbox
                                    name="macService"
                                    checked={formData.macService}
                                    disabled={true} 
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Chargeable?</td>
                              <td>
                                <UILIB.Checkbox
                                    name="chargeable"
                                    checked={formData.chargeable}
                                    disabled={true} 
                                />
                              </td>
                            </tr>
                          </table>
                        </UILIB.Paper>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-8 mar-b25">
                        <div className="row">
                          <div className="col-xs-12 col-sm-6 col-xs-6 mar-b25">
                            <UILIB.Paper style={{ height: "100%" }}>
                              <div className="mar-b15 mar-t5"><b>Collection Information:</b></div>
                              <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                                <tr>
                                  <td valign="top">Collection Address</td>
                                  <td>
                                    <UILIB.TextArea
                                        type="text"
                                        className="textInput input"
                                        name="collectionAddress"
                                        style={{ width: "100%", height: "120px", padding: "0", "resize": "none" }} 
                                        value={formData.collectionAddress}
                                        disabled={true} 
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Name</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="collectionName"
                                        placeholder="Contact Name"
                                        value={formData.collectionName}
                                        disabled={true} 
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Telephone</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="collectionTelephone"
                                        placeholder="Contact Telephone"
                                        value={formData.collectionTelephone}
                                        disabled={true} 
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Email</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="collectionEmail"
                                        placeholder="Contact Email"
                                        value={formData.collectionEmail}
                                        disabled={true} 
                                    />
                                  </td>
                                </tr>
                              </table>
                            </UILIB.Paper>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-xs-6 mar-b25">
                            <UILIB.Paper style={{ height: "100%" }}>
                              <div className="mar-b15 mar-t5"><b>Delivery Information:</b></div>
                              <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                                <tr>
                                  <td valign="top">Delivery Address</td>
                                  <td>
                                    <UILIB.TextArea
                                        type="text"
                                        className="textInput input"
                                        name="delAddress"
                                        style={{ width: "100%", height: "120px", padding: "0", "resize": "none" }} 
                                        value={formData.delAddress}
                                        disabled={true} 
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Name</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="delContactName"
                                        placeholder="Contact Name"
                                        value={formData.delContactName}
                                        disabled={true} 
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Email</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="delContactEmail"
                                        placeholder="Contact Email"
                                        value={formData.delContactEmail}
                                        disabled={true} 
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Telephone</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="delContactTelephone"
                                        placeholder="Contact Telephone"
                                        value={formData.delContactTelephone}
                                        disabled={true} 
                                    />
                                  </td>
                                </tr>
                              </table>
                            </UILIB.Paper>
                          </div>
                        </div>
                        <div className="col-xs-12" style={{ padding: "0" }}>
                          <UILIB.Paper style={{ height: "100%" }} >
                            <div className="row">
                              <div className="col-xs-12 col-md-4 mar-b5">
                                  <div className="mar-b15 mar-t5"><b>General Access:</b></div>
                                  <UILIB.TextArea
                                      name="genAccessNotes"
                                      className="textInput input"
                                      style={{ width: "100%", height: "100px", padding: "0", "resize": "none" }} 
                                      value={formData.genAccessNotes}
                                      disabled={true} 
                                  />
                              </div>
                              <div className="col-xs-12 col-md-4 mar-b5">
                                  <div className="mar-b15 mar-t5"><b>IT requirements:</b></div>
                                  <UILIB.TextArea
                                      name="itRequirementsNotes"
                                      className="textInput input"
                                      style={{ width: "100%", height: "100px", padding: "0", "resize": "none" }} 
                                      value={formData.itRequirementsNotes}
                                      disabled={true} 
                                  />
                              </div>
                              <div className="col-xs-12 col-md-4 mar-b5">
                                  <div className="mar-b15 mar-t5"><b>Logistics Notes:</b></div>
                                  <UILIB.TextArea
                                      className="textInput input"
                                      name="logisticsNotes"
                                      style={{ width: "100%", height: "100px", padding: "0", "resize": "none" }} 
                                      value={formData.logisticsNotes}
                                      disabled={true} 
                                  />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12 col-md-5" style={{ padding: 10 }}>
                                <div className="mar-b15 mar-t20 mar-l5"><b>Devices Require Network Connection:</b></div>
                                <table width="100%" cellPadding="0" cellSpacing="0" className="subTable" style={{maring: 0}}>
                                  <tr>
                                    <td width="25%">If Yes, provide IT contact details</td>
                                    <td width="25%">
                                      <input
                                          type="radio"
                                          name="requiresNetwork"
                                          placeholder="Yes"
                                          value="Yes"
                                          defaultChecked
                                          checked={formData.requiresNetwork === "No" ? false : true}
                                          disabled={true}  /> Yes
                                      <input
                                          type="radio"
                                          name="requiresNetwork"
                                          placeholder="No"
                                          value="No"
                                          checked={formData.requiresNetwork === "Yes" ? false : true}
                                          disabled={true}  /> No
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="25%">Contact Name</td>
                                    <td width="25%">
                                      <UILIB.TextInput
                                          type="text"
                                          placeholder="Contact Name"
                                          value={formData.contactNameNetwork}
                                          className="textInput input"
                                          name="contactNameNetwork"
                                          disabled={true} 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="25%">Contact Email</td>
                                    <td width="25%">
                                      <UILIB.TextInput
                                          type="text"
                                          placeholder="Contact Email"
                                          value={formData.contactEmailNetwork}
                                          className="textInput input"
                                          name="contactEmailNetwork"
                                          disabled={true} 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="25%">Contact Telephone</td>
                                    <td width="25%">
                                      <UILIB.TextInput
                                          type="text"
                                          placeholder="Contact Telephone"
                                          value={formData.contactTelephoneNetwork}
                                          className="textInput input"
                                          name="contactTelephoneNetwork"
                                          disabled={true} 
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="col-xs-12 col-md-7" style={{ padding: 10 }}>
                                <div className="mar-b15 mar-t20 mar-l5"><b>Details of any existing PS:</b></div>
                                  <table width="100%" cellPadding="0" cellSpacing="0" className="subTable" style={{margin: 0}}>
                                    <tr>
                                      <td width="30%">Address books?</td>
                                      <td width="70%">
                                        <UILIB.Checkbox
                                          name="psAddressBook"
                                          checked={formData.psAddressBook}
                                          disabled={true}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>KPAX/E-maintenance/FM</td>
                                      <td>
                                        <UILIB.Checkbox
                                          name="psKpax"
                                          checked={formData.psKpax}
                                          disabled={true}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Print management</td>
                                      <td>
                                        <UILIB.Checkbox
                                          name="psPrintManagement"
                                          checked={formData.psPrintManagement}
                                          disabled={true}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td valign="top">Other </td>
                                      <td colspan="5">
                                        <UILIB.TextArea
                                          type="text"
                                          className="textInput input"
                                          name="psOther"
                                          style={{ width: "100%", height: "80px", padding: "0", "resize": "none" }} 
                                          value={formData.psOther}
                                          disabled={true}
                                        />  
                                      </td>
                                    </tr>
                                </table>
                              </div>
                            </div>
                          </UILIB.Paper>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="row">
                <div className="col-xs-12 mar-b15">
                  <UILIB.Paper style={{ width: "100%", height: "100%" }}>
                      <div className="col-xs-12 mar-b20">
                          <div className="mar-b15 mar-t5 mar-b20"><label><b>Customer Signature & Acknowledgement</b></label></div>
                          <div className="mar-b15 mar-t5 mar-l20 text-small">By e-signing this document, I confirm the movement of the above items.</div>
                      </div>
                      <div className="row">
                          <div style={{ width: "120px", height: "100%" }} className="mar-l20 mar-b15 mar-t15"><label>Signed Name:</label></div>
                          <div style={{ width: "300px", height: "100%" }} className="mar-l20 mar-b15 mar-t5">
                              <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="signedName"
                                  placeholder="Signed Name"
                                  outerclassNname="mar-b10"
                                  value={formData.quote[0].signedName}
                                  onChange={(event) => updateQuoteData(event)}
                                  errored={formErrors.signedName.error.value
                                      ? { messageText: formErrors.signedName.value, option: 1 }
                                      : { messageText: "", option: 0 }
                                  }
                              />
                          </div>
                      </div>
                      <div className="row">
                          <div style={{ width: "120px", height: "100%" }} className="mar-l20 mar-b15 mar-t15"><label>Signed Date:</label></div>
                          <div style={{ width: "300px", height: "100%" }} className="mar-l20 mar-b15 mar-t5">
                              <UILIB.TextInput
                                  type="date"
                                  className="textInput input"
                                  name="signedDate"
                                  placeholder="Signed Date"
                                  outerclassNname="mar-b10"
                                  value={formData.quote[0].signedDate}
                                  onChange={(event) => updateQuoteData(event)}
                                  errored={formErrors.signedDate.error.value
                                      ? { messageText: formErrors.signedDate.value, option: 1 }
                                      : { messageText: "", option: 0 }
                                  }
                              />
                          </div>
                      </div>
                      <div className="col-xs-12 flex-container mar-b10">
                        {!submitted && <div>
                          <UILIB.Button
                            value="Sign and close"
                            onClick={() => submitForm(formData.id, formData, history)} 
                          />
                        </div>}
                        {submitted && <div>
                          <UILIB.Button loading={true} />
                        </div>}
                      </div>
                  </UILIB.Paper>
                </div>
              </div>
            </Fragment>

}


