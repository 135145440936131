import moment from 'moment'

export function formatCurrency(input, hide, decimals) {
    if (!input) input = 0
    var negative = false
    if (input < 0) negative = true;
    input = Math.abs(input)
    var str = input.toLocaleString()
    if (decimals === 0) {
        return '£' + str;
    }
    var num = input.toFixed(2)
    if (negative) return (!hide ? '-£' : '') + str.split('.')[0] + '.' + num.split('.')[1]
    return (!hide ? '£' : '') + str.split('.')[0] + '.' + num.split('.')[1]
}

export function formatNumber(input, decimals) {
    if (isNaN) input = 0;
    if (typeof input != "number") input = Number(input);
    input = input.toFixed(decimals)
    return input;
}
export function formatDateTime(input, format) {
    if (input === 'NA' || !input) return "NA";
    return moment(input).utc().format(format ? format : 'DD/MM/YYYY HH:mm:ss');
}

