import Axios from 'classes/axios';
import UILIB from 'components';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as mime from 'react-native-mime-types';


export const queueJobBase = { 
  jobId: null, 
  name: null, 
  uploadRoute: null, 
  jobRoute: null,
  isActive: 0, 
  complete: 0, 
  trigger: 0, 
  timeout: null, 
  progress: null 
}

export const tableSortHandlerBase = { 
  active: false, 
  direction: 'asc', 
  columnFilters: null,
  headName: null, 
  offset: 0, 
  limit: 40, 
  filter: 'View All Rows'
}

export function documentStatusData(index) {
  const dataArray = [
    { label: 'All Documents', value: 'd=include' },
    { label: 'View Active', value: 'unlinked' },
    { label: 'View Deleted', value: 'd=includeANDp28!=' }
  ];
  if(!isNaN(index)) return dataArray[index];
  return dataArray;
}

export function uploadClassData(index) {
  const dataArray = [
    { label: 'View All Uploads', id: 0 },
    { label: 'Driver Upload', id: 1 },
    { label: 'Ownership Upload', id: 2 },
    { label: 'Vehicle Upload', id: 3 }
  ];
  if(!isNaN(index)) return dataArray[index];
  return dataArray;
}

export function statusClassData(index) {
  const dataArray = [
    { label: 'View All Statuses', id: 0 },
    { label: 'Upload Complete', id: 'Upload Complete' },
    { label: 'Rollback Data Transaction', id: 'Rollback Data Transaction' }
  ];
  if(!isNaN(index)) return dataArray[index];
  return dataArray;
}


export function addTextFieldMUI(size, label, name, variant, value, onChange, sx, inputProps) {
  return (<TextField 
    size={size}
    inputProps={inputProps}
    id="outlined-basic" 
    label={label}
    variant={variant}
    sx={sx}
    name={name}
    value={value ? String(value) : null}
    onChange={(ev) => { onChange(ev, arguments) }}
  />)
}

export function addSelectFieldUILIB(name, disabled, style, data, value, onChange) {
  return (<UILIB.Select 
    name={name || ''}
    disabled={disabled || false} 
    outerclassNname={(style && style.outerClass) ? style.outerClass : ''}
    className={(style && style.class) ? style.class : ''}
    style={(style && style.style) ? style.style : { }} 
    data={data} 
    value={String(value)} 
    onChange={(event) => { onChange(event); }} 
  />)
}

export function addDownloadButtonUILIB(disabled, id, fileName, get, set, onClick) {
  return (
    <UILIB.Button 
      className={get[id] && get[id].error ? "table red text-small width-100" : "table orange text-small width-100"} 
      disabled={disabled}
      onClick={async () => await onClick()} 
      value={
        (!get[id] && <div className="table grey text-small width-100">{fileName}</div>) ||
        (get[id] && !get[id].status && !get[id].error && <div><p className="table grey text-small width-100">{fileName}</p></div>) ||
        (get[id].status && !get[id].error && <UILIB.Loading type={2}/>) ||
        (!get[id].status && get[id].error && <div><p>{String(get[id].message)}</p></div>)}      
    />)
}

export async function downloadFileHandler(method, url, id, fileName, ext, get, set, args) {

  try {
    set(d => ({ ...d, [id]: { status: true, error: false, message: 200 }}));
    const uploadResult = await Axios({ method: method, url: url, ...args });
    const link = document.createElement('a');
    link.download = `${fileName + (fileName.indexOf(".") > -1 ? '' : '.' + ext)}`;
    link.href = `data:${mime.lookup(ext)};base64,` + uploadResult.data.result;
    document.body.appendChild(link);
    link.click();
    set(d => ({ ...d, [id]: { status: false, error: false, message: 200 }}));
  }
  catch (error) {
    const response = error.message.response.data.message;
    set(d => ({ ...d, [id]: { status: false, error: true, message: response }}));
  }
}

export async function uploadFile(props) {
  props.setUploadFileHandler({ ...structuredClone(props.uploadFileHandler), open: true, stage: 1 })
  props.setFileUploadJobActive({ ...structuredClone(queueJobBase), 
    dbModel: props.dbModel,
    name: props.name,
    uploadRoute: props.uploadRoute, 
    jobRoute: props.jobRoute 
  });
  props.setSelectedFile(null);
}

export async function handleFileUpload(props, pollRate) {
  const classId = (props.tableFilter && props.tableFilter.classId) ? props.tableFilter.classId : null;
  const entityName = (props.fileUploadJobActive && props.fileUploadJobActive.name) ? props.fileUploadJobActive.name : null;
  const uploadRoute = (props.fileUploadJobActive && props.fileUploadJobActive.uploadRoute) ? props.fileUploadJobActive.uploadRoute : null;
  const data = new FormData();
  data.append('file', props.selectedFile);
  data.append('uploadFileHandler', JSON.stringify(props.uploadFileHandler));
  if(props.entity) data.append('entity', JSON.stringify({ ...props.entity, entityName: entityName, classId: classId }));
  const newJobResult = await Axios.put(uploadRoute, data);
  const activeUploadResult = await Axios.get(`${props.fileUploadJobActive.jobRoute}/${newJobResult.data.id}`);
  props.setFileUploadJobActive({ ...structuredClone(props.fileUploadJobActive),
    isActive: activeUploadResult.data.result.isActive,
    complete: activeUploadResult.data.result.complete,
    jobId: activeUploadResult.data.result.id,
    trigger: (props.fileUploadJobActive.trigger === 0 ? 1 : 0),
    timeout: pollRate
  });
}

export async function timerGetJobs(setFileUploadJobActive, fileUploadJobActive) {
  await new Promise(resolve => setTimeout(resolve, fileUploadJobActive.timeout));
  const fileUploadJobActiveNew = structuredClone(fileUploadJobActive);
  fileUploadJobActiveNew.trigger = (fileUploadJobActiveNew.trigger === 0 ? 1 : 0);
  setFileUploadJobActive(fileUploadJobActiveNew);
}

export async function getJob(setFileUploadJobActive, fileUploadJobActive) {
  const uploadResult = await Axios.get(`${fileUploadJobActive.jobRoute}/${fileUploadJobActive.jobId}`);
  fileUploadJobActive.progress = uploadResult.data.result.percentComplete;
  fileUploadJobActive.isActive = uploadResult.data.result.isActive;
  fileUploadJobActive.complete = uploadResult.data.result.complete;
  fileUploadJobActive.status = uploadResult.data.result.status;
  setFileUploadJobActive(fileUploadJobActive);
}

export async function handleSelectedFile(event, props) {
  props.setSelectedFile(event.currentTarget.files[0] || null);
}

export async function handCloseUploadModal(props) {
  props.setUploadFileHandler({ show: false, open: false });
  props.setSelectedFile(null);
  if(props.setRender) props.setRender(!props.render);
}

export function getUploadType(row) {
  if(!row || !row.jobName) return;
  const uploadType = { type: 'N/A' };
  switch(true) {
    case (row.jobName.indexOf('driverUpload') > -1):
      uploadType.type = 'Driver Upload';
      break;
    case (row.jobName.indexOf('vehicleUpload') > -1):
      uploadType.type = 'Vehicle Upload';
      break;
    case (row.jobName.indexOf('fleetOwnership') > -1):
      uploadType.type = 'Ownership Upload';
      break;
    default:
      break;
  }
  return uploadType.type;
}

export function rowToObj(line) {
  const { id, ...lineNew } = line;
  return <div>{Object.entries(lineNew).map((prop, index) => {
    return <div key={index}>{prop[0]}: <span className="text-grey">{prop[1]}</span> </div>
  })}</div>
}

export function handleModalState(props) {
  if(getUploadType(props.uploadedRowsModal) === 'Driver Upload') return { get: props.driverTableSortHandler, set: props.setDriverTableSortHandler };
  if(getUploadType(props.uploadedRowsModal) === 'Vehicle Upload') return { get: props.vehicleTableSortHandler, set: props.setVehicleTableSortHandler };
  if(getUploadType(props.uploadedRowsModal) === 'Ownership Upload') return { get: props.driverVehicleTableSortHandler, set: props.setDriverVehicleTableSortHandler };
  return { get: null, set: null }
}

export function handleModalStyle(props, opts) {
  if(opts === 'upload') {
    if(getUploadType(props.uploadedRowsModal) === 'Driver Upload') return { width: "90%", height: "60%"};
    if(getUploadType(props.uploadedRowsModal) === 'Vehicle Upload') return { width: "90%", height: "60%"};
    if(getUploadType(props.uploadedRowsModal) === 'Ownership Upload') return { width: "90%", height: "60%"};
  }
  if(opts === 'documents') {
    if(props.entityName === 'Driver') return { width: "80%", height: "60%"};
  }
  return { width: "90%", height: "60%"};
}

export function toastContainer(ms, width) {
  return <ToastContainer
    progressClassName="toastify"
    position="bottom-right"
    autoClose={ms}
    style={{ width: width }}
    hideProgressBar={false}
    newestOnTop={true}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
}

export async function exportData(exporting, setExporting) {
  setExporting({ ...structuredClone(exporting), setExporting: true })
  toast.info("Exporting, please wait");
  try {
      const res = await Axios.get(exporting.exportUrl);
      const link = document.createElement('a');
      link.download = `${exporting.fileName}.csv`;
      link.href = 'data:text/csv;base64,' + res.data;
      document.body.appendChild(link);
      link.click();
  } catch (e) {
    console.log(e)
    toast.error("Error exporting, please try again");
  }
  setExporting({ ...structuredClone(exporting), setExporting: false })
};